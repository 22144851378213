import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import DataTable from '../common/DataTable'
import DonwloadReportDialog from '../common/DonwloadReportDialog'
import Toaster from '../common/Toaster'
import AddExpedienteSupplier from './components/create/AddExpedienteSupplier'
import DocumentsContainerTable from './components/expedientDocuments/DocumentsContainerTable'
import IndexEdit from './IndexEdit'
/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import ExpedientsIcon from '@material-ui/icons/FormatListNumbered'
import ChangeViewIcon from '@material-ui/icons/ViewCarousel'
import DownloadIcon from '@material-ui/icons/CloudDownload'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import AddExpedientIcon from '@material-ui/icons/AddTwoTone'
import Fab from '@material-ui/core/Fab'
import {
	Button,
	CircularProgress,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Tooltip,
} from '@material-ui/core'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import {
	loadExpedientSuppliersFromBackEnd,
	CHANGE_EXPEDIENT_SUPPLIERS_SORT_CRITERIA,
	CHANGE_EXPEDIENT_SUPPLIERS_PAGINATION,
	CHANGE_EXPEDIENT_SUPPLIERS_SORT_QUERY,
	getExpedientSuppliersCount,
	downloadExpedientsReportByRangeDate,
	createDocumentType,
	loadAllDocumentsClasiffications,
	loadAllSupplierClasiffications,
	downloadFileClassificationReport,
	moveDocument,
	downloadHistoryDeleteExpedientsReport,
	deleteMassiveExpedient,
} from '../../store/expedientSupplier/ExpedientSupplierAction'
import { converListToArray } from '../../store/helpers/ListHelper'
import { canBeModuleExpeditByCompany } from '../../store/helpers/RolesHelper'
import { showErrorToaster, showSuccessToaster } from '../../store/helpers/ToasterHelpers'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'
import { Assignment, Delete } from '@material-ui/icons'
import OptionsMenu from '../common/OptionsMenu'
import { COMPANY_IDENTIFIER_IMBERA_MX } from '../../store/helpers/ResourcesHelper'
import Dialog from '../common/Dialog'

/**
 * Expedient supplier view
 *
 * @class Expedient supplier
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance of Expedient suppliers
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			itemToken: null,
			showMenu: true,
			openModalByMonthReport: false,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			isChangeModeView: false,
			openLateralPanelCreateExpedient: false,
			identifierPanelLateral: '',
			item: null,
			confirmPasswordConfigModal: {
				opened: false,
				item: null,
			},
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadExpedientSuppliers(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.loadAllDocumentsClasiffications()
			this.props.loadAllSupplierClasiffications()
			this.props.getExpedientSuppliersCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadExpedientSuppliers()
			this.props.getExpedientSuppliersCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadExpedientSuppliers(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		const { classes } = this.props
		if (dataSource === 'description') {
			return <div style={{ width: '230px' }}>{item.description}</div>
		}
		if (dataSource === 'logo') {
			return (
				<Avatar data-cy='oficial-avatar' className={!item.is_closed ? classes.avatar : ''}>
					<ExpedientsIcon />
				</Avatar>
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	onConfirmPassword = (item, identifier) => {
		this.setState({
			confirmPasswordConfigModal: {
				opened: !this.state.confirmPasswordConfigModal.opened,
				item: item,
				identifier: identifier,
			},
		})
	}

	onLateralPanelConfig = (identifier, item) => {
		this.setState({
			identifierPanelLateral: identifier,
			item: item,
		})
	}

	onOpenCloseLateralPanelCreateExpedient = () => {
		this.setState({
			openLateralPanelCreateExpedient: !this.state.openLateralPanelCreateExpedient,
		})
	}

	onCreateDocumentClassification = (model) => {
		this.props
			.createDocumentType(model)
			.then((_) => this.onOpenCloseLateralPanelCreateExpedient())
			.catch((response) => {
				this.setState({
					showToaster: true,
					toasterMessage: IsNullOrEmpty(response)
						? 'common.errorToSave'
						: 'expedientSupplier.errors.invalidNomenclatureAlreadyInTemplate',
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Function to add new expedient supplier
	 */
	onClickAddButton() {
		this.props.history.push(`/expedientsupplier/create`)
	}

	/**
	 * Get expedient Suppliers counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getExpedientSupplierCounterMessage() {
		const { classes } = this.props
		if (this.props.expedientSuppliers) {
			let totalexpedientSuppliers = this.props.expedientSuppliers.length
			return this.state.isChangeModeView === false ? (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span className={classes.showingCounter}>{totalexpedientSuppliers}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.expedientSupplierCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			) : (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span className={classes.showingCounter}>{this.props.documents.length}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.documents.length}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Handle click of options menu component to set item Token in state nd open menu
	 *
	 * @param {*} token
	 * @memberof Index
	 */
	handleClickOptionsMenu(token) {
		this.setState({
			itemToken: token,
		})
	}

	/**
	 * Handle on close optons menu to set void item token in state
	 *
	 * @memberof Index
	 */
	handleCloseOptionsMenu() {
		if (!this.state.openModalByMonthReport) {
			this.setState(
				{
					itemToken: '',
					showMenu: false,
				},
				() => {
					this.setState({ showMenu: true })
				}
			)
		}
	}

	/**
	 * Open modal for set report by mont
	 *
	 * @memberof Index
	 */
	handleOpenModal() {
		this.setState({
			openModalByMonthReport: true,
		})
	}

	/**
	 * Close modal to report by month
	 *
	 * @memberof Index
	 */
	handleCloseModal() {
		this.setState(
			{
				openModalByMonthReport: false,
			},
			() => this.handleCloseOptionsMenu()
		)
		this.setState({ startDate: '', endDate: '' })
	}

	/**
	 *Download report of from contacts
	 *
	 * @memberof Index
	 */
	handleDownloadReportByDateRange(startDate, endDate) {
		this.props
			.downloadExpedientsReportByRangeDate(startDate, endDate)
			.then(() => {
				this.setState(showSuccessToaster('common.downloadCorrectly'))
				this.handleCloseModal()
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToDownload'))
			})
	}
	handleDonwlaodHistoryDeleteExpedients() {
		this.props
			.downloadHistoryDeleteExpedientsReport()
			.then(() => {
				this.setState(showSuccessToaster('common.downloadCorrectly'))
				this.handleCloseModal()
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToDownload'))
			})
	}

	handleDownloadFileClassificationReport() {
		this.props
			.downloadFileClassificationReport()
			.then(() => {
				this.setState(showSuccessToaster('common.downloadCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToDownload'))
			})
	}

	handleMoveDocumentToIndex(id, toIndex) {
		this.props.moveDocument(id, toIndex)
	}
	/**
	 * an Seelct all items onSelectedChecks
	 * @param {*} selectedTokens
	 */
	onSelectedChecks(selectedTokens) {
		this.setState({ selectedTokens})
	}
	getModifayTableConfiguration() {
		let config = [...TableConfiguration.columns]
		if (
			canBeModuleExpeditByCompany(this.props.userRolesByCompany) &&
			this.props.companyIdentifier === COMPANY_IDENTIFIER_IMBERA_MX
		)
			config.unshift({
				header: '',
				dataSource: 'selectCheck',
			})
		return { columns: config }
	}
	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		const modifayTableConfiguration = this.getModifayTableConfiguration()
		if (this.props.isLoadingExpedientSuppliers === true) {
			return <LoadingPage />
		} else if (this.props.expedientSuppliers === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<>
					{this.state.isChangeModeView === false && (
						<DataTable
							data={this.props.expedientSuppliers}
							configuration={modifayTableConfiguration}
							onRenderCellItem={this.onRenderCellItem}
							sortBy={sortCriteria.by}
							sortAscending={sortCriteria.ascending}
							onChangeSortCriteria={this.onChangeSortCriteria}
							onRowClick={(expedient) => {
								this.props.history.push(`/expedientsupplier/${expedient.expendient_token}`)
							}}
							showFilters
							isIndex
							totalRows={this.props.expedientSupplierCount}
							page={this.props.page}
							rowsPerPage={this.props.rowsPerPage}
							onChangePage={(newPage, newRowsPerPage) => {
								this.props.changePage(newPage, newRowsPerPage)
							}}
							onSelectedCheck={(selectedTokens) => {
								this.onSelectedChecks(selectedTokens)
							}}
							selectedTokens={this.state.selectedTokens}
						/>
					)}

					{this.state.isChangeModeView === true && (
						<DocumentsContainerTable
							documents={this.props.documents}
							supplierClassifications={this.props.supplierClassifications}
							onLateralPanelConfig={this.onLateralPanelConfig}
							identifierPanelLateral={this.state.identifierPanelLateral}
							onConfirmPassword={this.onConfirmPassword}
							isOpenLateralMenu={this.props.isOpenLateralMenu}
							userRolesByCompany={this.props.userRolesByCompany}
							handleMoveDocumentToIndex={this.handleMoveDocumentToIndex}
						/>
					)}
				</>
			)
		}
	}
	renderAction() {
		var options = []

		options.push({
			itemClick: () => this.handleOpenModal(),
			tooltipTranslation: <Translate id='expedientSupplier.downloadReportByMonth' />,
			menuItemIcon: <DownloadIcon />,
			isLoading: this.props.isSaving,
		})
		options.push({
			itemClick: () => this.handleDonwlaodHistoryDeleteExpedients(),
			tooltipTranslation: <Translate id='expedientSupplier.historyDeleteDownload' />,
			menuItemIcon: <Assignment />,
			isLoading: this.props.isSaving,
		})
		if (this.state.showMenu) {
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={this.handleClickOptionsMenu}
					open={this.state.itemToken === 'item.token'}
					handleClickAway={this.handleCloseOptionsMenu}
					options={options}
				/>
			)
		}
	}
	deleteMassiveExpedient() {
		this.props.deleteMassiveExpedient(this.state.selectedTokens, this.state.deleteComment).then(() => {
			this.setState({ selectedTokens: [], deleteComment: null })
			this.setState({ openDeleteModal: false })
			this.props.loadExpedientSuppliers(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		})
	}
	/**
	 * Render
	 *
	 * @returns
	 * @memberof ExpedientSupplier
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<ExpedientsIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							{!this.state.isChangeModeView && (
								<SearchInput onChangeSearchValue={this.onChangeSearchValue} />
							)}
							{canBeModuleExpeditByCompany(this.props.userRolesByCompany) && (
								<Tooltip
									title={
										this.state.isChangeModeView === false ? (
											<Translate id={'expedientSupplier.addExpedient'} />
										) : (
											<Translate id={'expedientSupplier.addDocument'} />
										)
									}
								>
									<Fab
										className={classes.categoryIcon}
										size='small'
										color={this.state.isChangeModeView ? 'secondary' : 'primary'}
										onClick={() => {
											this.state.isChangeModeView === false
												? this.onLateralPanelConfig('CreateExpedient')
												: this.onOpenCloseLateralPanelCreateExpedient()
										}}
									>
										<AddExpedientIcon />
									</Fab>
								</Tooltip>
							)}
							{canBeModuleExpeditByCompany(this.props.userRolesByCompany) && (
								<Tooltip title={<Translate id='indirectqualifications.changeView' />}>
									<Fab
										size='small'
										color={this.state.isChangeModeView ? 'primary' : 'secondary'}
										className={classes.categoryIcon}
										onClick={() =>
											this.setState({ isChangeModeView: !this.state.isChangeModeView })
										}
									>
										<ChangeViewIcon />
									</Fab>
								</Tooltip>
							)}

							{canBeModuleExpeditByCompany(this.props.userRolesByCompany) &&
								this.state.isChangeModeView === true && (
									<Tooltip title={<Translate id='expedientSupplier.fileClasificationReport' />}>
										<Fab
											size='small'
											color={'secondary'}
											className={classes.categoryIcon}
											onClick={() => this.handleDownloadFileClassificationReport()}
											isSaving={this.props.isSaving}
										>
											<DownloadIcon />
										</Fab>
									</Tooltip>
								)}
							{canBeModuleExpeditByCompany(this.props.userRolesByCompany) &&
								this.props.companyIdentifier === COMPANY_IDENTIFIER_IMBERA_MX &&
								this.state.selectedTokens &&
								this.state.selectedTokens.length > 0 && (
									<Tooltip title={<Translate id='expedientSupplier.deleteExpedients' />}>
										{this.props.isSaving ? (
											<CircularProgress className={classes.deleteButtonProgress} />
										) : (
											<Fab
												size='small'
												className={classes.deleteButton}
												onClick={() => this.setState({ openDeleteModal: true })} // Abre la modal
											>
												<Delete />
											</Fab>
										)}
									</Tooltip>
								)}

							{/* Modal de confirmación de eliminación */}
							<Dialog
								open={this.state.openDeleteModal}
								onClose={() => this.setState({ openDeleteModal: false })}
							>
								<DialogTitle>
									<Translate id='expedientSupplier.confirmDeleteTitle' />
								</DialogTitle>
								<DialogContent>
									<DialogContentText>
										<Translate id='expedientSupplier.confirmDeleteMessage' />
									</DialogContentText>
									<TextField
										fullWidth
										multiline
										rows={3}
										variant='outlined'
										label={<Translate id='expedientSupplier.addComment' />}
										value={this.state.deleteComment}
										onChange={(e) => this.setState({ deleteComment: e.target.value })}
									/>
								</DialogContent>
								<div
									style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px', gap: '8px' }}
								>
									<Button onClick={() => this.setState({ openDeleteModal: false })} color='primary'>
										<Translate id='expedientSupplier.cancel' />
									</Button>
									<Button
										onClick={() => this.deleteMassiveExpedient()}
										className={classes.deleteButton}
										variant='contained'
										disabled={this.props.isSaving}
									>
										<Translate id='expedientSupplier.confirmDelete' />
									</Button>
								</div>
							</Dialog>

							{canBeModuleExpeditByCompany(this.props.userRolesByCompany) &&
								this.state.isChangeModeView === false &&
								(this.props.companyIdentifier === COMPANY_IDENTIFIER_IMBERA_MX ? (
									this.renderAction()
								) : (
									<Tooltip title={<Translate id='expedientSupplier.downloadReportByMonth' />}>
										<Fab
											size='small'
											color={this.state.isChangeModeView ? 'primary' : 'secondary'}
											className={classes.categoryIcon}
											onClick={() => this.handleOpenModal()}
											isSaving={this.props.isSaving}
										>
											<DownloadIcon />
										</Fab>
									</Tooltip>
								))}
						</div>
					}
					title={
						<Typography variant='button'>
							{this.state.isChangeModeView === false ? (
								<Translate id='expedientSupplier.expedientTitle' />
							) : (
								<Translate id='expedientSupplier.expedientDocumentsTitle' />
							)}
						</Typography>
					}
					subheader={this.getExpedientSupplierCounterMessage()}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to download report */}
				<DonwloadReportDialog
					title={<Translate id='expedientSupplier.downloadReportByMonth' />}
					open={this.state.openModalByMonthReport}
					onClose={this.handleCloseModal}
					isSaving={this.props.isSaving}
					onDonwloadItems={this.handleDownloadReportByDateRange}
				/>
				{this.state.openLateralPanelCreateExpedient && (
					<AddExpedienteSupplier
						onCloseButtonClick={this.onOpenCloseLateralPanelCreateExpedient}
						onSaveButtonClick={this.onCreateDocumentClassification}
						isSaving={this.props.isSaving}
					/>
				)}
				{
					<IndexEdit
						identifierPanelLateral={this.state.identifierPanelLateral}
						setIdentifierPanelLateral={() => this.onLateralPanelConfig()}
						onLateralPanelConfig={this.onLateralPanelConfig}
						item={this.state.item}
						onConfirmPassword={this.onConfirmPassword}
						confirmPasswordConfigModal={this.state.confirmPasswordConfigModal}
						supplierClassifications={this.props.supplierClassifications}
					/>
				}

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'formated_folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='expedientSupplier.nameExpedient' />,
			dataSource: 'description',
			isSortable: true,
		},
		{
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
			isSortable: true,
		},
		{
			header: <Translate id='expedientSupplier.supplierType' />,
			dataSource: 'supplier_type_description',
		},
		{
			header: <Translate id='common.closingDate' />,
			dataSource: 'closed_date',
			isSortable: true,
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	let documentsClassification = state.expedientSuppliers.get('documents')
	let supplierClassifications = state.expedientSuppliers.get('supplierClassifications')
	return {
		user: user,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingExpedientSuppliers: state.expedientSuppliers.get('isLoadingExpedientSuppliers'),
		isSaving: state.expedientSuppliers.get('isSavingExpedientSupplier'),
		expedientSuppliers: state.expedientSuppliers.get('expedientSuppliers')
			? state.expedientSuppliers.get('expedientSuppliers').toJS()
			: null,
		sortCriteria: state.expedientSuppliers.get('sortCriteria')
			? state.expedientSuppliers.get('sortCriteria').toJS()
			: null,
		searchQuery: state.expedientSuppliers.get('searchQuery'),
		page: state.expedientSuppliers.get('page'),
		rowsPerPage: state.expedientSuppliers.get('rowsPerPage'),
		expedientSupplierCount: state.expedientSuppliers.get('expedientSupplierCount'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		documents: documentsClassification ? converListToArray(documentsClassification) : [],
		supplierClassifications: supplierClassifications ? converListToArray(supplierClassifications) : [],
		isOpenLateralMenu: state.profile.get('isOpenLateralMenu'),
		translate: getTranslate(state.localize),
		companyIdentifier: state.profile.get('selectedCompany').get('get_company_identifier'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadExpedientSuppliers: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadExpedientSuppliersFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_EXPEDIENT_SUPPLIERS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_EXPEDIENT_SUPPLIERS_SORT_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_EXPEDIENT_SUPPLIERS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		getExpedientSuppliersCount: () => {
			return dispatch(getExpedientSuppliersCount())
		},
		downloadExpedientsReportByRangeDate: (startDate, endDate) => {
			return dispatch(downloadExpedientsReportByRangeDate(startDate, endDate))
		},
		downloadHistoryDeleteExpedientsReport: () => {
			return dispatch(downloadHistoryDeleteExpedientsReport())
		},
		createDocumentType: (expedientModel) => {
			return dispatch(createDocumentType(expedientModel))
		},
		loadAllDocumentsClasiffications: () => {
			return dispatch(loadAllDocumentsClasiffications())
		},
		loadAllSupplierClasiffications: () => {
			return dispatch(loadAllSupplierClasiffications())
		},
		downloadFileClassificationReport: () => {
			return dispatch(downloadFileClassificationReport())
		},
		moveDocument: (id, toIndex) => {
			return dispatch(moveDocument(id, toIndex))
		},
		deleteMassiveExpedient: (selectedTokens, comments) => {
			return dispatch(deleteMassiveExpedient(selectedTokens, comments))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	categoryIcon: {
		marginLeft: '5px',
	},
	deleteButton: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.error.main,
		marginLeft: '5px',
	},
	deleteButtonProgress: {
		color: theme.palette.error.main,
		marginLeft: '5px',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
