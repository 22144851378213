import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'auto-bind'
import uuidv4 from 'uuid/v4'
import { Translate, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

/** Component imports section */
import Select from 'react-select'
import { components } from 'react-select'
import DataTablePaginationActions from './DataTablePaginationActions'

/** Material UI imports section */
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableFooter from '@material-ui/core/TableFooter'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Checkbox from '@material-ui/core/Checkbox'
import FilterListIcon from '@material-ui/icons/FilterList'

/** Material UI Icons imports section */
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import FilterIcon from '@material-ui/icons/FilterList'

/** Actions and Helpers imports section */
import {
	BACKGROUND_COLOR_GRAY,
	COLOR_STATUS_INFO,
	COLOR_STATUS_INPROCESS,
	COLOR_STATUS_WARNING,
	COLOR_STATUS_DRAFT,
	COLOR_STATUS_COMPLETED,
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_CONFLICT,
	COLOR_STATUS_DANGER,
	COLOR_STATUS_GRAY,
	COLOR_SUSTAINABILITY_PLUM,
} from './../../store/helpers/StatusColorConstants'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'

const getVisibleColumns = (columns) => {
	if (!Array.isArray(columns)) return []
	const visibleColumns = columns.filter((column) => !column.hasOwnProperty('visible') || column.visible)
	// console.log('🚀 ~ file: DataTable.js ~ line 51 ~ getVisibleColumns ~ vivibleColumns', visibleColumns)
	return visibleColumns
}

/**
 * Class to render Data table
 *
 * @class DataTable
 * @extends {Component}
 */
class DataTable extends Component {
	/**
	 *Creates an instance of DataTable.
	 * @param {*} props
	 * @memberof DataTable
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			expanded: '',
			itemsWithAdditionalRow: [],
			filters: {},
			refresh: true,
			rowsPerPage: this.props.rowsPerPage ? this.props.rowsPerPage : 20,
			page: this.props.page ? this.props.page : 1,
			selectedTokens: this.props.selectedTokens ? this.props.selectedTokens : [],
			isSelectedAll: this.props.selectAllChecked || false,
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.selectedTokens && this.props.selectedTokens !== prevProps.selectedTokens) {
			this.setState({ selectedTokens: this.props.selectedTokens })
		}
	}

	/**
	 * On change the sort criteria
	 *
	 * @param {*} dataSource
	 * @memberof DataTable
	 */
	onChangeSortCriteria(event, dataSource) {
		const { tagName } = event.target
		if (tagName !== 'svg') {
			if (dataSource === this.props.sortBy) {
				this.props.onChangeSortCriteria(dataSource, !this.props.sortAscending)
			} else {
				this.props.onChangeSortCriteria(dataSource, false)
			}
		}
	}

	/**
	 * Handle state to expand or collapse card content
	 *
	 * @memberof HistoryCard
	 */
	handleExpand(itemToken) {
		this.setState({
			expanded: IsNullOrEmpty(this.state.expanded) ? itemToken : '',
		})
	}

	onCheck(itemToken, isChecked) {
		let selectedTokens = [...this.state.selectedTokens]
		console.log({itemToken})
		if (isChecked) {
			if (itemToken === 'selectAll') {
				

				if(Object.keys(this.state.filters).length>0)
					{
						var filter=this.filterData(this.props.data, getVisibleColumns(this.props.configuration.columns))
						filter.forEach((item) => {
							if (selectedTokens.findIndex((itemToken) => itemToken === item.token) < 0 && !item.is_hidden)
								selectedTokens.push(item.token)
						})
					}
					else 
					{
						this.props.data.forEach((item) => {
							if (selectedTokens.findIndex((itemToken) => itemToken === item.token) < 0 && !item.is_hidden)
								selectedTokens.push(item.token)
						})
					}
			} else {
				this.props.data.forEach((item) => {
					if (item.token === itemToken && !item.is_hidden) selectedTokens.push(itemToken)
				})
				// selectedTokens.push(itemToken)
			}
		} else {
			if (itemToken === 'selectAll') selectedTokens = []
			else {
				selectedTokens = this.state.selectedTokens.filter((token) => {
					return token !== itemToken
				})
			}
		}
		if (this.props.onSelectAllChange && itemToken === 'selectAll') {
			this.props.onSelectAllChange(isChecked)
		}
		if (this.props.onSelectRowChange && itemToken !== 'selectAll') {
			this.props.onSelectRowChange(itemToken, isChecked)
		}
		this.setState(
			{
				selectedTokens,
				isSelectedAll: itemToken === 'selectAll' && isChecked,
			},
			() => {
				this.props.onSelectedCheck(this.state.selectedTokens)
			}
		)
	}

	/**
	 * Determine ifcolumn is valid to show selector filter
	 *
	 * @param {*} dataSource
	 * @memberof DataTable
	 */
	isValidColumnToFilter(dataSource) {
		if (
			dataSource === 'options' ||
			dataSource === 'expand' ||
			dataSource === 'selectCheck' ||
			dataSource === 'status_indicator' ||
			dataSource === 'tracking' ||
			dataSource === 'avatar_url' ||
			dataSource === 'logo' ||
			dataSource === 'is_active' ||
			dataSource === 'enabled' ||
			dataSource === 'edit'
			// dataSource === 'Responsibles'
		)
			return false
		return true
	}

	/**
	 * Function to set flag and refresh list
	 *
	 * @memberof DataTable
	 */
	refreshList() {
		this.setState({ refresh: false }, () => this.setState({ refresh: true }))
	}

	/**
	 * Function to set filters in state
	 *
	 * @param {*} filter
	 * @param {*} event
	 * @memberof DataTable
	 */
	setFilter(filter, event) {
		if (!event) {
			delete this.state.filters[filter]
			this.refreshList()
		} else {
			this.props.onSelectedCheck([])
			this.setState({ isSelectedAll: false });
			this.setState({
				filters: {
					...this.state.filters,
					[filter]: event.value,
				},
			})
		}
	}

	/**
	 * Function to filter data
	 *
	 * @param {*} originData
	 * @returns
	 * @memberof DataTable
	 */
	filterData(originData, columns) {
		let filteredData = originData
		Object.keys(this.state.filters).forEach((key) => {
			let existFilter = columns.find((x) => x.dataSource === key)
			if (existFilter && existFilter.isArrayStringFilter) {
				filteredData = filteredData.filter((data) => {
					return data[key].includes(this.state.filters[key])
				})
			} else {
				filteredData = filteredData.filter((data) => {
					return data[key] === this.state.filters[key]
				})
			}
		})
		return filteredData
	}

	/**
	 * Function to determine color of status indicator
	 *
	 * @param {*} status
	 * @returns
	 * @memberof DataTable
	 */
	getStatusColor(status) {
		if (status === COLOR_STATUS_INPROCESS) return COLOR_STATUS_WARNING
		if (status === COLOR_STATUS_COMPLETED) return COLOR_STATUS_SUCCESS
		if (status === COLOR_STATUS_CONFLICT) return COLOR_STATUS_DANGER
		if (status === COLOR_STATUS_DRAFT) return COLOR_STATUS_GRAY
		if (status === COLOR_STATUS_DRAFT) return COLOR_STATUS_GRAY
		if (status === 'sendLog') return COLOR_SUSTAINABILITY_PLUM
		return COLOR_STATUS_INFO
	}

	handleChangePage(event, newPage) {
		this.setState({ page: newPage }, () => {
			this.props.onChangePage(this.state.page, this.state.rowsPerPage)
		})
	}

	handleChangeRowsPerPage(event) {
		this.setState(
			{
				rowsPerPage: parseInt(event.target.value, 10),
				page: 1,
			},
			() => {
				this.props.onChangePage(this.state.page, this.state.rowsPerPage)
			}
		)
	}

	handleFilterClick(event, dataSource) {
		event.stopPropagation()
		const { tagName } = event.target
		if (this.props.onFilterClick && (tagName === 'svg' || tagName === 'path')) {
			this.props.onFilterClick(event, dataSource)
		}
	}

	/**
	 * Render button to expand or contract sub rows
	 *
	 * @param {*} isExpanded
	 * @param {*} itemToken
	 * @returns
	 * @memberof DataTable
	 */
	renderExpandIcon(isExpanded, itemToken) {
		let xsButton = { maxWidth: 30, maxHeight: 30, paddingTop: 3, backgroundColor: BACKGROUND_COLOR_GRAY }
		return (
			<Tooltip title={<Translate id={isExpanded ? 'common.clickToContract' : 'common.clickToExpand'} />}>
				<IconButton
					color='primary'
					aria-label='More'
					style={xsButton}
					onClick={() => {
						this.handleExpand(itemToken)
					}}
				>
					{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
				</IconButton>
			</Tooltip>
		)
	}

	/**
	 * Method to render Chcek box to select row item  or all items
	 *
	 * @param {*} isSelected
	 * @param {*} itemToken
	 * @returns
	 * @memberof DataTable
	 */
	renderSelectionCheck(isSelected, itemToken, isPrincipal = false) {
		return (
			<Tooltip
				title={`${this.props.translate(isSelected ? 'common.unselect' : 'common.select')} ${
					isPrincipal ? this.props.translate('common.all') : ''
				}`}
				placement='top'
			>
				<Checkbox
					onClick={(event) => {
						event.stopPropagation()
					}}
					onChange={(event) => {
						this.onCheck(event.target.value, event.target.checked)
					}}
					color='primary'
					value={itemToken}
					checked={isSelected}
					style={{ padding: '0px' }}
				/>
			</Tooltip>
		)
	}

	/**
	 *Method to render TableHead
	 *
	 * @param {*} configuration
	 * @returns
	 * @memberof DataTable
	 */
	renderTableHeader(configuration, dataWithFilter) {
		return (
			<TableHead key={uuidv4()}>
				{this.props.title && (
					<TableRow key={uuidv4()}>
						<TableCell
							key={uuidv4()}
							colSpan={getVisibleColumns(configuration.columns).length}
							style={{
								backgroundColor: '#F6F6F6',
								position: 'sticky',
								top: 0,
								zIndex: '30',
							}}
						>
							{this.props.title}
						</TableCell>
					</TableRow>
				)}
				<TableRow key={uuidv4()}>
					{getVisibleColumns(configuration.columns).map((column) => {
						let maxPaddingCell =
							column.dataSource === 'options' ||
							column.dataSource === 'expand' ||
							column.dataSource === 'selectCheck'
								? 0
								: column.dataSource === 'status_indicator'
								? 3
								: 12
						let maxWidthCell =
							column.dataSource === 'options' ||
							column.dataSource === 'expand' ||
							column.dataSource === 'selectCheck'
								? 30
								: '1fr'
						let isSortedBy = column.dataSource === this.props.sortBy
						let sortOrder = this.props.sortAscending ? 'asc' : 'desc'
						return (
							<TableCell
								key={uuidv4()}
								align={
									this.props.alignCellContent
										? this.props.alignCellContent
										: column.dataSource === 'selectCheck'
										? 'center'
										: 'left'
								}
								padding='none'
								style={{
									paddingLeft: maxPaddingCell,
									paddingRight: maxPaddingCell,
									maxWidth: maxWidthCell,
									backgroundColor: column.backgroundColor ? column.backgroundColor : '#fff',
									position: 'sticky',
									top: this.props.title ? 55 : 0,
									zIndex: '30',
								}}
							>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										color: column.color ? column.color : null,
									}}
								>
									{column.isSortable === true ? (
										<Tooltip
											title='Sort'
											placement={1 ? 'bottom-end' : 'bottom-start'}
											enterDelay={300}
										>
											<TableSortLabel
												active={isSortedBy}
												direction={sortOrder}
												onClick={(event) => {
													this.onChangeSortCriteria(event, column.dataSource)
												}}
											>
												{column.header}
											</TableSortLabel>
										</Tooltip>
									) : column.dataSource !== 'selectCheck' ? (
										column.header
									) : (
										this.renderSelectionCheck(this.state.isSelectedAll, 'selectAll', true)
									)}
									{column.filterEnabled && (
										<IconButton
											style={{ height: 15, width: 15 }}
											onClick={(event) => this.handleFilterClick(event, column.dataSource)}
										>
											<FilterListIcon size='small' />
										</IconButton>
									)}
								</div>
							</TableCell>
						)
					})}
				</TableRow>
				{this.props.showFilters && (
					<TableRow key={uuidv4()}>
						{getVisibleColumns(configuration.columns).map((column) => {
							let maxPaddingCell =
								column.dataSource === 'options' ||
								column.dataSource === 'expand' ||
								column.dataSource === 'selectCheck'
									? 0
									: column.dataSource === 'status_indicator'
									? 3
									: 12
							let maxWidthCell =
								column.dataSource === 'options' ||
								column.dataSource === 'expand' ||
								column.dataSource === 'selectCheck'
									? 30
									: '1fr'
							if (this.isValidColumnToFilter(column.dataSource)) {
								let options = []
								/**Valida que si existen filtros tome los items filtrados para actualizar las opciones en el selector de filtros*/
								let itemsInTable =
									Object.keys(this.state.filters).length === 0 ? this.props.data : dataWithFilter
								if (column.isArrayStringFilter) {
									let allOptionsUniques = []
									options = [...new Set(itemsInTable.map((item) => item[column.dataSource]))].filter(
										(v, i, a) => a.indexOf(v) === i && !IsNullOrEmpty(v)
									)
									options.forEach((option) => {
										option.forEach((filter) => {
											if (!allOptionsUniques.includes(filter)) allOptionsUniques.push(filter)
										})
									})
									options = allOptionsUniques.map((item) => {
										return { value: item, label: item }
									})
								} else {
									/** Get all data values by column, then does map to make objects foreach value and add them to the options array */
									options = [...new Set(itemsInTable.map((item) => item[column.dataSource]))]
										.filter((v, i, a) => a.indexOf(v) === i && !IsNullOrEmpty(v))
										.map((item) => {
											return { value: item, label: item }
										})
								}
								let selectedValue = options.find((option) => {
									return option.value === this.state.filters[column.dataSource]
								})
								return (
									<TableCell
										key={uuidv4()}
										align={this.props.alignCellContent ? this.props.alignCellContent : 'left'}
										padding='none'
										style={{
											paddingLeft: maxPaddingCell,
											paddingRight: maxPaddingCell,
											maxWidth: maxWidthCell,
											backgroundColor: '#fff',
											position: 'sticky',
											top: this.props.title ? 110 : 55,
											zIndex: '10',
										}}
									>
										{this.isValidColumnToFilter(column.dataSource) && (
											<Select
												id={column.dataSource}
												value={selectedValue}
												onChange={(event) => this.setFilter(column.dataSource, event)}
												placeholder={<Translate id='common.selectTofilter' />}
												options={options}
												isClearable
												isSearchable
												styles={{
													control: (base) => ({
														...base,
														maxHeight: 30,
														minHeight: 0,
														width: this.props.widthFilters,
													}),
													input: (base) => ({ ...base, maxHeight: 20, minWidth: 50 }),
													menu: (base) => ({ ...base, zIndex: 62, maxHeight: 150 }),
													menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER ANY ELEMENT
													menuList: (base) => ({ ...base, maxHeight: 150, paddingTop: 0 }),
													indicatorContainer: (base) => ({
														...base,
														maxWidth: 28,
														padding: 0,
													}),
													clearIndicator: (base) => ({
														...base,
														color: '#ff0000',
														padding: 0,
													}),
													dropdownIndicator: (base) => ({ ...base, padding: 0 }),
													indicatorSeparator: () => null,
												}}
												components={{ DropdownIndicator }}
												menuPortalTarget={document.parentNode}
												// menuPosition="absolute"
												menuPlacement={'bottom'}
											/>
										)}
									</TableCell>
								)
							} else {
								return (
									<TableCell
										key={uuidv4()}
										align='center'
										padding='none'
										style={{
											paddingLeft: maxPaddingCell,
											paddingRight: maxPaddingCell,
											maxWidth: maxWidthCell,
											backgroundColor: '#fff',
											position: 'sticky',
											top: this.props.title ? 110 : 55,
											zIndex: '30',
										}}
									/>
								)
							}
						})}
					</TableRow>
				)}
			</TableHead>
		)
	}

	/**
	 * Method to render label "From to" on pagination footer
	 *
	 * @param {*} from
	 * @param {*} to
	 * @param {*} count
	 * @returns
	 * @memberof DataTable
	 */
	renderFromToPage(from, to, count) {
		//FIX NaN VALUES
		if (isNaN(from)) from = ''
		if (isNaN(to)) to = ''
		//${<Translate id="common.of"/>} ADD TRANSLATION IN "de" fragment of label
		if (count > 0) return `${from}-${to} ${this.props.translate('common.of')} ${count}`
		return <Translate id='pagination.noRecords' />
	}

	renderFooter(colSpan, totalItems) {
		totalItems = totalItems == null ? 0 : totalItems
		return (
			<TableFooter>
				<TableRow key={uuidv4()}>
					<TablePagination
						rowsPerPageOptions={[10, 20, 50]}
						colSpan={colSpan}
						count={totalItems}
						rowsPerPage={this.props.rowsPerPage}
						page={this.props.page}
						labelRowsPerPage={<Translate id='pagination.rowsPerPage' />}
						labelDisplayedRows={({ from, to, count }) => this.renderFromToPage(from, to, count)}
						SelectProps={{
							inputProps: { 'aria-label': 'Rows por page' },
							native: true,
						}}
						onChangePage={this.handleChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
						ActionsComponent={DataTablePaginationActions}
						style={{
							backgroundColor: '#fff',
							position: 'sticky',
							bottom: -0.5,
							zIndex: '30',
						}}
					/>
				</TableRow>
			</TableFooter>
		)
	}

	render() {
		var { configuration, data, onRenderCellItem, onRenderCellAditionalRowItem, aditionalRows } = this.props
		//Only index tables should have a max height  and enable scroll
		let paperStyle = this.props.isIndex
			? {
					maxWidth: '100%',
					minHeight: 100,
					maxHeight: '69vh',
					overflowY: 'auto',
			  }
			: {}

		if (Object.keys(this.state.filters).length > 0)
			data = this.filterData(data, getVisibleColumns(configuration.columns))
		return (
			<React.Fragment>
				<Paper style={paperStyle}>
					<Table style={{ maxWidth: '100%', minHeight: 90, maxHeight: '100%' }} size='small'>
						{this.renderTableHeader(configuration, data)}
						<TableBody key={uuidv4()}>
							{data !== undefined && data.length > 0 && this.state.refresh ? (
								data.map((item) => {
									return (
										<React.Fragment key={uuidv4()}>
											<TableRow
												hover
												size='small'
												key={uuidv4()}
												style={{
													cursor: this.props.onRowClick ? 'pointer' : '',
													height: this.props.rowHeight || 48,
												}}
												onClick={(event) => {
													if (this.props.onRowClick) {
														this.props.onRowClick(item, event)
													}
												}}
											>
												{getVisibleColumns(configuration.columns).map((column) => {
													let cellContent
													let maxPaddingCell =
														column.dataSource === 'options' ||
														column.dataSource === 'expand' ||
														column.dataSource === 'selectCheck'
															? 0
															: column.dataSource === 'status_indicator'
															? 3
															: 12
													let maxWidthCell =
														column.dataSource === 'options' ||
														column.dataSource === 'expand' ||
														column.dataSource === 'selectCheck'
															? 30
															: '1fr'
													let backgroundCellStatus = item.color_status_table
														? this.getStatusColor(item.color_status_table)
														: COLOR_STATUS_INFO //status_color must be in object data to show color bar
													if (
														column.dataSource === 'expand' &&
														item.has_valid_additional_data
													) {
														//has_valid_additional_data must be in object data info to show children row
														let isExpanded =
															this.state.expanded ===
															(item.token ? item.token : item.Id ? item.Id : '')
														cellContent = this.renderExpandIcon(
															isExpanded,
															item.token ? item.token : item.Id ? item.Id : ''
														)
													} else if (column.dataSource === 'selectCheck') {
														let isSelected =
															this.state.selectedTokens.findIndex(
																(token) => token === item.token
															) >= 0
														cellContent = this.renderSelectionCheck(isSelected, item.token)
													} else if (onRenderCellItem) {
														let overridedContent = onRenderCellItem(column.dataSource, item)
														if (!overridedContent) {
															cellContent = item[column.dataSource]
														} else {
															cellContent = overridedContent
														}
													} else {
														cellContent = item[column.dataSource]
													}
													return (
														<TableCell
															key={uuidv4()}
															align={
																this.props.alignCellContent
																	? this.props.alignCellContent
																	: 'left'
															}
															padding='none'
															style={{
																backgroundColor:
																	column.dataSource === 'status_indicator'
																		? backgroundCellStatus
																		: item.custom_color,
																wordBreak: 'break-word',
																paddingLeft: maxPaddingCell,
																paddingRight: maxPaddingCell,
																maxWidth: maxWidthCell,
															}}
														>
															{cellContent}
														</TableCell>
													)
												})}
											</TableRow>
											{/** Aditional Row render */}
											{aditionalRows &&
												aditionalRows.rows.map((aditionalRow) => {
													let cellAditionalContent
													let value = aditionalRow.propertyInObject
														? item[aditionalRow.dataSource][aditionalRow.propertyInObject]
														: item[aditionalRow.dataSource]
													if (onRenderCellAditionalRowItem) {
														let overridedContent = onRenderCellAditionalRowItem(
															aditionalRow.dataSource,
															item
														)
														if (!overridedContent) {
															cellAditionalContent = value
														} else {
															cellAditionalContent = overridedContent
														}
													} else {
														cellAditionalContent = value
													}
													if (
														(aditionalRow.type === 'dataString' && !IsNullOrEmpty(value)) ||
														(aditionalRow.type === 'dataList' && value.length > 0) ||
														(aditionalRow.type === 'dataObject' &&
															Object.keys(value).length > 0)
													) {
														return (this.state.expanded ===
															(item.token ? item.token : item.Id ? item.Id : '') ||
															!this.props.collapsableChild) &&
															cellAditionalContent !== 'skip' ? (
															<TableRow
																key={uuidv4()}
																style={{
																	cursor: this.props.onAditionalRowClick
																		? 'pointer'
																		: '',
																	height: this.props.rowHeight || 48,
																}}
																onClick={(event) => {
																	if (this.props.onAditionalRowClick) {
																		this.props.onAditionalRowClick(
																			aditionalRow,
																			event
																		)
																	}
																}}
															>
																<TableCell
																	key={uuidv4()}
																	align={
																		this.props.alignCellContent
																			? this.props.alignCellContent
																			: 'left'
																	}
																	padding='none'
																	colSpan={
																		getVisibleColumns(configuration.columns).length
																	}
																	style={{ wordBreak: 'break-word' }}
																>
																	{cellAditionalContent}
																</TableCell>
															</TableRow>
														) : (
															''
														)
													} else return ''
												})}
										</React.Fragment>
									)
								})
							) : (
								<TableRow key={uuidv4()} hover size='small'>
									<TableCell
										key={uuidv4()}
										align='center'
										padding='none'
										colSpan={getVisibleColumns(configuration.columns).length}
										style={{ backgroundColor: '#fafafa' }}
									>
										<Translate id='common.notInformation' />
									</TableCell>
								</TableRow>
							)}
						</TableBody>
						{this.props.isIndex &&
							this.props.totalRows !== undefined &&
							this.renderFooter(
								getVisibleColumns(configuration.columns).length + 1,
								this.props.totalRows
							)}
					</Table>
				</Paper>
			</React.Fragment>
		)
	}
}

/** Custom DropDown indicator component in select filter */
const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<FilterIcon color='inherit' />
		</components.DropdownIndicator>
	)
}

DataTable.propTypes = {
	configuration: PropTypes.object.isRequired,
	title: PropTypes.any, //Prop can be object, compoent or string its the head of tht table
	data: PropTypes.array.isRequired,
	onRenderCellItem: PropTypes.func,
	onRenderCellAditionalRowItem: PropTypes.func,
	aditionalRows: PropTypes.object,
	collapsableChild: PropTypes.bool, //Prop to determine if parent row has collapsable child
	alignCellContent: PropTypes.string, //Optional Prop to determine cell content align

	onChangePage: PropTypes.func, //Function to call action to list index by page
	// onChangeRowsPerPage: PropTypes.func//Function to call action to list index by rows per page
	onFilterClick: PropTypes.func,
	onSelectRowChange: PropTypes.func,
	onSelectAllChange: PropTypes.func,
	selectAllChecked: PropTypes.bool,
	onSelectedCheck: PropTypes.func,
}

DataTable.defaultProps = {
	onSelectedCheck: () => console.warn('onSelectedCheck not defined!'),
}

/**
 *  Define the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	return {
		translate: getTranslate(state.localize),
	}
} //test

export default connect(mapStateToProps, null)(DataTable)
