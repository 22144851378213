/** Import ReactJS */
import React from 'react'
import Select from 'react-select'
import moment from 'moment'

import { withRouter } from 'react-router-dom'
//omport components section
import ListDocumentsSit from '../../../exportRequest/components/show/DocumentsSit'
/** Material UI import section */
import { Avatar, Card, Grid, Divider, Typography, TextField, ListItemSecondaryAction, ListItem, List, ListItemAvatar, ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import AssignmentIcon from '@material-ui/icons/Assignment'

import FolderIcon from '@material-ui/icons/AttachFile'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

/**Import section components */
/**Import resources section */
import { COLOR_STATUS_ALERT_INFO } from '../../../../store/helpers/StatusColorConstants'
import DatePicker from '../../../common/DateTimePicker/DatePicker'
import { GetCurrencyTypeContract } from '../../../../store/helpers/SelectOptions'
import { Translate } from 'react-localize-redux'
import { onGetRequestTypeDescription } from '../../../../store/helpers/indirectPriceQuoteWithoutCodeHelper'
import Evidence from '../../../common/Evidence'
/** Resources import section */
const useStyle = (theme) => ({
	card: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
		padding: 0,
		'&:last-child': {
			padding: 0,
		},
	},
	cardcontent: {
		padding: 0,
		'&:last-child': {
			padding: 0,
		},
	},
	textTitle: {
		fontWeight: 'bold !important',
		// color: theme.palette.primary.main,
	},
})

const HeaderComponent = (props) => {
	const { classes, indirectPriceQuoteSupplier } = props
	//Function props
	const { updatePriceQuoteWithOutCodeSupplierProperty, onUploadFilesPriceQuoteWithOutCodeSupplier, onRemoveFilesPriceQuoteWithOutCodeSupplier } = props
	//Bool props section
	const { isSaving, canBeEditSupplier } = props

	const onChangeDate = (propertyName, event) => {
		let objectProperty = {}
		objectProperty[propertyName] = event
		if (props.updatePriceQuoteWithOutCodeSupplierProperty) {
			updatePriceQuoteWithOutCodeSupplierProperty(indirectPriceQuoteSupplier.token, objectProperty)
		}
	}

	const onUpdateProperty = (event) => {
		let objectProperty = {}
		if (props.updatePriceQuoteWithOutCodeSupplierProperty) {
			objectProperty[event.target.name] = event.target.value
			updatePriceQuoteWithOutCodeSupplierProperty(indirectPriceQuoteSupplier.token, objectProperty)
		}
	}
	let currencyOptions = GetCurrencyTypeContract()

	let currencyValue = indirectPriceQuoteSupplier.Currency ? parseInt(indirectPriceQuoteSupplier.Currency, 10) : null

	let selectedCurrency = currencyOptions.find((x) => x.value === currencyValue)


	return (
		<Grid container spacing={16}>
			<Grid item xs={12}>
				<Card>
					<CardHeader
						className={classes.card}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<AssignmentIcon />
							</Avatar>
						}
						title={<Translate id='factoringAffiliation.show.creatorInformation' />}
					/>
					<Divider />
					<CardContent>
						<Grid container spacing={8}>
							<Grid item xs={3}>
								<Typography className={classes.textTitle}>Tipo de solicitud</Typography>
								<Typography>
									{indirectPriceQuoteSupplier
										? onGetRequestTypeDescription(
												indirectPriceQuoteSupplier.IndirectPriceQuoteWithoutCode.RequestType
										  )
										: '-'}
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.createdBy' />
								</Typography>
								<Typography>
									{indirectPriceQuoteSupplier
										? indirectPriceQuoteSupplier.IndirectPriceQuoteWithoutCode.CreateBy
										: '-'}
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.user' />
								</Typography>
								<Typography>
									{indirectPriceQuoteSupplier
										? indirectPriceQuoteSupplier.IndirectPriceQuoteWithoutCode.CreatorUserName
										: '-'}
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.emailCreator' />
								</Typography>
								<Typography>
									{indirectPriceQuoteSupplier
										? indirectPriceQuoteSupplier.IndirectPriceQuoteWithoutCode.CreateEmail
										: '-'}
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader
						className={classes.card}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<AssignmentIcon />
							</Avatar>
						}
						title={'Información general'}
					/>
					<Divider />
					<CardContent>
						<Grid container spacing={8}>
							<Grid item xs={2}>
								<Typography className={classes.textTitle}>Fecha de envío a cotizar</Typography>
								<Typography>
									{indirectPriceQuoteSupplier &&
									indirectPriceQuoteSupplier.IndirectPriceQuoteWithoutCode
										? indirectPriceQuoteSupplier.IndirectPriceQuoteWithoutCode.DateSend
										: '-'}
								</Typography>
							</Grid>

							<Grid item xs={5}>
								<Typography className={classes.textTitle}>Moneda</Typography>
								<Select
									options={currencyOptions}
									styles={{
										// menu: (base) => ({ ...base, zIndex: 10 })
										menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
										menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
										menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
									}}
									menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
									menuPlacement={'auto'}
									onChange={(data) => onChangeDate('Currency', data.value)}
									value={selectedCurrency}
									isDisabled={isSaving || !canBeEditSupplier}
								/>
							</Grid>
							<Grid item xs={5}>
								<Typography className={classes.textTitle}>Vigencia de cotización</Typography>

								{!canBeEditSupplier ? (
									<Typography className={classes.textTitle}>
										{indirectPriceQuoteSupplier.ServiceValidityDate}
									</Typography>
								) : (
									<DatePicker
										// opens={'left'}
										onChangeRange={(event) => onChangeDate('ServiceValidityDate', event)}
										disabled={!canBeEditSupplier}
										startDate={
											indirectPriceQuoteSupplier.ServiceValidityDate
												? moment(indirectPriceQuoteSupplier.ServiceValidityDate)
												: ''
										}
										minDate={moment()}
									/>
								)}
							</Grid>
							<Grid item xs={12}>
								<Typography className={classes.textTitle}>Condiciones</Typography>
								<TextField
									name='Conditions'
									defaultValue={
										indirectPriceQuoteSupplier ? indirectPriceQuoteSupplier.Conditions : '-'
									}
									fullWidth
									margin='dense'
									variant='outlined'
									multiline
									rowsMax={3}
									onBlur={onUpdateProperty}
									disabled={isSaving || !canBeEditSupplier}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography className={classes.textTitle}>Lugar de entrega</Typography>
								<TextField
									name='DeliveryLocation'
									defaultValue={
										indirectPriceQuoteSupplier ? indirectPriceQuoteSupplier.DeliveryLocation : '-'
									}
									fullWidth
									margin='dense'
									variant='outlined'
									multiline
									rowsMax={3}
									onBlur={onUpdateProperty}
									disabled={isSaving || !canBeEditSupplier}
								/>
							</Grid>

							{/* <Grid item xs={12}>
								<CardHeader
									className={classes.card}
									avatar={
										<Avatar aria-label='Recipe' className={classes.avatar}>
											<AssignmentIcon />
										</Avatar>
									}
									title={'Archivos compartidos con el proveedor'}
								/>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								{indirectPriceQuoteSupplier && indirectPriceQuoteSupplier.AttachmentsPlots && (
									<ListDocumentsSit
										title='common.plotsAttanchments'
										documents={indirectPriceQuoteSupplier.AttachmentsPlots}
									/>
								)}
							</Grid> */}

							{/* <Grid item xs={12}>
								<CardHeader
									className={classes.card}
									avatar={
										<Avatar aria-label='Recipe' className={classes.avatar}>
											<AssignmentIcon />
										</Avatar>
									}
									title={'Archivos compartidos por el proveedor'}
								/>
								<Divider />
							</Grid> */}
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader
						className={classes.card}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<AssignmentIcon />
							</Avatar>
						}
						title={'Archivos compartidos con el proveedor'}
					/>
					<Divider />
					<CardContent>
						<Grid container spacing={8}>
							<Grid item xs={12}>
								{indirectPriceQuoteSupplier && indirectPriceQuoteSupplier.AttachmentsPlots && (
									<ListDocumentsSit
										title='common.plotsAttanchments'
										documents={indirectPriceQuoteSupplier.AttachmentsPlots}
									/>
								)}
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CardHeader
						className={classes.card}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<AssignmentIcon />
							</Avatar>
						}
						title={'Archivos compartidos por el proveedor'}
					/>
					<Divider />
					<CardContent>
						<Grid container spacing={8}>
							<Grid item xs={12}>
								{canBeEditSupplier && (
									<Evidence
										files={[]}
										// maxFiles={1}
										uploadFiles={(files) => onUploadFilesPriceQuoteWithOutCodeSupplier(files)}
									/>
								)}

								<List>
									{indirectPriceQuoteSupplier &&
										indirectPriceQuoteSupplier.AttachmentsSupplier.map((file) => {
											return (
												<>
													<ListItem className={classes.fileItem}>
														<ListItemAvatar>
															<Avatar>
																<FolderIcon />
															</Avatar>
														</ListItemAvatar>
														<ListItemText
															primary={file.name}
															// secondary={secondary ? 'Secondary text' : null}
														/>
														<ListItemSecondaryAction>
															<IconButton
																aria-label='download'
																href={`${process.env.REACT_APP_SIP_URL_FILES}${file.relativePath}`}
																target='_blank'
																disabled={isSaving}
															>
																<CloudDownloadIcon />
															</IconButton>
															{canBeEditSupplier && (
																<IconButton
																	aria-label='Delete'
																	onClick={() =>
																		onRemoveFilesPriceQuoteWithOutCodeSupplier(
																			file.name,
																		)
																	}
																	disabled={isSaving}
																>
																	<DeleteIcon />
																</IconButton>
															)}
														</ListItemSecondaryAction>
													</ListItem>
													<Divider />
												</>
											)
										})}
									{indirectPriceQuoteSupplier && indirectPriceQuoteSupplier.AttachmentsSupplier.length === 0 && (
										<Typography>No existen archivos cargados hasta el momento</Typography>
									)}
								</List>

								{/* <CardHeader
									className={classes.card}
									avatar={
										<Avatar aria-label='Recipe' className={classes.avatar}>
											<AssignmentIcon />
										</Avatar>
									}
									title={'Archivos compartidos por el proveedor'}
								/>
								<Divider /> */}
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CardHeader
						className={classes.card}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<AssignmentIcon />
							</Avatar>
						}
						title={'Información de la cotización'}
					/>
				</Card>
			</Grid>
		</Grid>
	)
}

HeaderComponent.propTypes = {}

HeaderComponent.defaultProps = {}
export default withRouter(withStyles(useStyle, { withTheme: true })(HeaderComponent))
