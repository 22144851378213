import Drawer from '@material-ui/core/Drawer'
import { withStyles } from '@material-ui/core/styles'
import autoBind from 'auto-bind'
import classnames from 'classnames'
import React, { Component } from 'react'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import Badge from '@material-ui/core/Badge'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import GuaranteeReportIcon from '@material-ui/icons/FileCopyOutlined'
import SearchIcon from '@material-ui/icons/FindInPageTwoTone'
import InvoicesIcon from '@material-ui/icons/ListAltTwoTone'
import ProspectSupplierIncon from '@material-ui/icons/LocalShipping'
import WarehouseEntriesIcon from '@material-ui/icons/LocalShippingTwoTone'
import ConsignationIcon from '@material-ui/icons/RvHookupTwoTone'
import PurchaseOrdersIcon from '@material-ui/icons/ShoppingCartTwoTone'
import InventoryIcon from '@material-ui/icons/StorageTwoTone'
import WarehouseWindowsIcon from '@material-ui/icons/Store'
import InventoryMaquilaIcon from '@material-ui/icons/ViewModule'

import QuotesIcon from '@material-ui/icons/AttachMoneyTwoTone'
import RatesIcon from '@material-ui/icons/BarChartTwoTone'
import SACRequestIcon from '@material-ui/icons/BugReportTwoTone'
import TrazabilityIcon from '@material-ui/icons/CallSplitTwoTone'
import MemosIcon from '@material-ui/icons/FileCopyTwoTone'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import SupplierDevelopmentIcon from '@material-ui/icons/SupervisedUserCircleTwoTone'
import AlternativeSupplierIcon from '@material-ui/icons/SwapCallsTwoTone'
import NoticesOfChangeIcon from '@material-ui/icons/SwapHorizTwoTone'
import SamplesIcon from '@material-ui/icons/UnarchiveTwoTone'

import ReworkIcon from '@material-ui/icons/AutorenewOutlined'
import RawMaterialNonConfIcon from '@material-ui/icons/BugReport'
import WarehouseRawMaterialIcon from '@material-ui/icons/FormatAlignJustify'
import DevolutionIcon from '@material-ui/icons/ReplyAllOutlined'
import SacsQualityIcon from '@material-ui/icons/Report'
import QualityAssuranceIcon from '@material-ui/icons/ThumbUpAltTwoTone'
import WarrantyQualityIcon from '@material-ui/icons/VerticalSplit'
import QualityFailureReportIcon from '@material-ui/icons/WarningOutlined'

import NoticesIcon from '@material-ui/icons/ChatTwoTone'
import UsersManagementIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import AdministrationIcon from '@material-ui/icons/SettingsTwoTone'

import DeliveryOrdersIcon from '@material-ui/icons/Assignment'
import BusinessIcon from '@material-ui/icons/BusinessTwoTone'
import PurchaseIcon from '@material-ui/icons/CalendarTodayOutlined'
import TutorialIcon from '@material-ui/icons/LibraryBooks'
import FreightIcon from '@material-ui/icons/LocalShipping'
import ShippingIcon from '@material-ui/icons/ShoppingCart'

import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ServicesProjectsIcon from '@material-ui/icons/Build'
import PhoneDirectorycon from '@material-ui/icons/ContactPhone'
import MaquilaIcon from '@material-ui/icons/Description'
import FindInPage from '@material-ui/icons/FindInPage'
import ExpedientsIcon from '@material-ui/icons/FormatListNumbered'
import AccreditedWorkShopsIcon from '@material-ui/icons/HowToReg'
import FactoringAfiliantionIcon from '@material-ui/icons/Money'
import { default as QuestionnaireIcon, default as SustainabilityIcon } from '@material-ui/icons/NaturePeople'
import EmployeesIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'
import IndirectQualificationIcon from '@material-ui/icons/RateReviewTwoTone'
import MedicalChecksIcon from '@material-ui/icons/SpellcheckOutlined'
import ContractorsIcon from '@material-ui/icons/SupervisedUserCircle'
import SocialSecurityIcon from '@material-ui/icons/VerifiedUser'
import BiddingIcon from '@material-ui/icons/ViewCarousel'
/** Actions import section */
import { loadAccreditedWorkshopStatus } from '../../store/accreditedWorkshops/accreditedWorkshopsActions'

/** Section to resources */
import {
	Assignment,
	CreditCard,
	CreditCardOutlined,
	Description,
	DoneAll,
	Notifications,
	Poll,
	School,
	Security,
	ViewList,
} from '@material-ui/icons'
import {
	COMPANY_IDENTIFIER_FERSA,
	COMPANY_IDENTIFIER_IMBERA_MX,
	COMPANY_IDENTIFIER_IMBERA_REPARE,
	COMPANY_IDENTIFIER__METALLIC,
	COMPANY_MAQUINAS_EOS,
	COMPANY_PTM,
	ENVIROMENT_TYPE_PRODUCTION,
} from '../../store/helpers/ResourcesHelper'
import * as roles from '../../store/helpers/RolesHelper'
import { canBeModuleExpedientSupplier } from '../../store/helpers/RolesHelper'
import { IsUserSupplierAdministrator } from '../../store/helpers/SessionHelper'
import { loadPartnership } from '../../store/suppliers/SupplierActions'
import HeaderLogosCompany from './HeaderLogosCompany'
/**
 * Lateral menu for external user
 *
 * @class LateralMenuExternalUser
 * @extends {Component}
 */
class LateralMenuExternalUser extends Component {
	/**
	 * Creates an instance of LateralMenuInternalUser.
	 * @param {*} props
	 * @memberof LateralMenuInternalUser
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			partnership: {
				is_inspection_free: false,
				is_carrier: false,
				off_set_delivery_days: 0,
				days_required_for_delivery_material: 0,
				time_hours_required_for_delivery_material: 0,
				is_automotive_supplier: false,
				number_ppms_by_supplier: 0,
				number_ramps: 2,
				is_requiered_quality_certificate: false,
				is_requiered_cerificate_by_template: false,
				is_supplier_to_add_carbon_foot_print: false,
				month_confirm: 0,
				is_plant_access_blocked: false,
				blockingReason: '',
				research: 0,
			},
		}
	}

	/**
	 * Implements componentDidMount from react life cycle
	 */
	componentDidMount() {
		const companyCode = this.props.user.profile.company_code
		this.props.fetchAccreditedWorkshopStatus(companyCode)
		if (this.props.selectedCompany) {
			this.props
				.loadPartnership(this.props.user.profile.company_code, this.props.selectedCompany.token)
				.then(() => {
					this.setState({
						partnership: { ...this.props.partnership },
					})
				})
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuInitialSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/':
				selectedOption = '/'
				break
			case '/officialnotices':
			case '/officialnotices/:token':
				selectedOption = 'officialnotices'
				break
			case '/shippingorders':
			case '/shippingorders/:token':
				selectedOption = 'shippingorders'
				break
			case '/freight':
			case '/freight/:token':
				selectedOption = 'freight'
				break
			case '/warehouseRawMaterials':
			case '/warehouseRawMaterials/:token':
				selectedOption = 'warehouseRawMaterials'
				break
			case '/deliveryQualifications':
			case '/deliveryQualifications/:token':
				selectedOption = 'deliveryQualifications'
				break
			case '/manualsportal':
				selectedOption = 'manualsportal'
				break
			case '/sustainability':
			case '/sustainability/:token':
				selectedOption = 'sustainability'
				break
			case '/questionnaire':
			case '/questionnaire/:token':
				selectedOption = 'questionnaire'
				break
			case '/accreditedWorkshops':
			case '/accreditedWorkshops/:token':
				selectedOption = 'accreditedWorkshops'
				break
			case '/biddings':
			case '/biddings/base/create/:token':
			case '/biddings/:token':
				selectedOption = 'biddings'
				break
			case '/maquilaOrders':
			case '/maquilaOrders/:token':
				selectedOption = 'maquilaOrders'
				break
			case '/sustainabilityv2':
			case '/sustainabilityv2/:token':
				selectedOption = 'sustainabilityv2'
				break
			case '/billings':
			case '/billing/:token':
				selectedOption = 'billings'
				break
			case '/consignmentNote':
			case '/consignmentNote/:token':
				selectedOption = 'consignmentNote'
				break
			case '/planning':
				selectedOption = 'planning'
				break
			case '/phoneDirectory':
			case '/phoneDirectory/:token/:activeTab':
				selectedOption = 'phoneDirectory'
				break
			case '/factoringaffiliation':
			case '/factoringaffiliation/:token':
				break
			case '/certifications':
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	/**
	 * Function is active module administration
	 */
	isActiveAdminsitration() {
		switch (this.props.match.path) {
			case '/suppliers/supplier/profile':
			case '/suppliers/ratings/:token':
			case '/externalusers':
			case '/externalusers/:token':
			case '/externalusers/profile/:token':
			case '/expedientsbysupplier':
			case '/expedientsbysupplier/:token':
				return true
			default:
				return false
		}
	}

	isActiveImports() {
		switch (this.props.match.path) {
			case '/importRequests':
			case '/importRequests/:token':
			case '/loadAlerts':
			case '/loadAlerts/:token':
				return true
			default:
				return false
		}
	}

	isActiveExports() {
		switch (this.props.match.path) {
			case '/exportRequests':
			case '/exportRequests/:token':
				return true
			default:
				return false
		}
	}

	/**
	 * Determine if contractors mudule is active
	 *
	 * @returns
	 * @memberof LateralMenuExternalUser
	 */
	isActiveContractors() {
		switch (this.props.match.path) {
			case '/employees':
			case '/employees/cards':
			case '/employees/:token':
				return true
			case '/socialinsurancepayments/':
			case '/socialinsurancepayments/:token':
				return true
			case '/servicesprojects/':
			case '/servicesprojects/:token':
				return true
			case '/medicalchecks/':
			case '/medicalchecks/:token':
				return true
			case '/trainings/':
			case '/trainings/:token':
				return true
			case '/securityCourses':
			case '/securityCourses/:token':
				return true
			default:
				return false
		}
	}

	/**
	 * Determine if planning mudule is active
	 *
	 * @returns
	 * @memberof LateralMenuExternalUser
	 */
	isActivePlanning() {
		switch (this.props.match.path) {
			case '/planning/releaseplan/:token':
			case '/planning/deliveryorder':
			case '/planning/deliveryorder/create':
			case '/planning/deliveryorder/:token':
				return true
			default:
				return false
		}
	}

	isActivePlanningFersa() {
		switch (this.props.match.path) {
			case '/planning':
			case '/planning/relist/:supplierCode':
			case '/planning/deliveryOrderFersa/create':
			case '/planning/deliveryOrderFersa/:token':
			case '/deliveryOrderFersa/registerarrival':
				return true
			default:
				return false
		}
	}

	/**
	 * Function is active module consults
	 */
	isActiveConsult() {
		switch (this.props.match.path) {
			case '/suppliers/invoices/:token':
			case '/suppliers/purchaseOrders/:token':
			case '/suppliers/inventories/:token':
			case '/suppliers/consignments/:token':
			case '/suppliers/inventorymaquila/:token':
			case '/suppliers/stocks/:token':
				return true
			default:
				return false
		}
	}

	/**
	 * Function is active module quality
	 */
	isActiveQuality() {
		switch (this.props.match.path) {
			case '/rawmaterialnonconformity':
			case '/rawmaterialnonconformity/:token':
			case '/qualityfailurereport':
			case '/qualityfailurereport/:token':
			case '/reworks':
			case '/reworks/:token':
			case '/reworks/reworkreport/:token':
			case '/sacsquality':
			case '/sacsquality/:token':
			case '/sacsquality/createActionsBySupplier/:token':
			case '/devolutions':
			case '/devolutions/:token':
			case '/qualityaudit':
			case '/qualityaudit/:token':
			case '/qualityCertificate':
			case '/qualityCertificate/:token':
			case '/specifications/guidelines':
			case '/specifications/guidelines/:token':
			case '/specifications/package':
			case '/specifications/package/:token':
				return true
			default:
				break
		}
	}

	/**
	 * Funtion is active module development
	 * @param {*} isActiveDevelopment
	 */
	isActiveDevelopment() {
		switch (this.props.match.path) {
			case '/memos':
			case '/memos/:token':
			case '/noticechanges':
			case '/noticechanges/:token':
			case '/noticechanges/notification/:token':
			case '/alternativeprovedure':
			case '/alternativeprovedure/:token':
			case '/materialdelivery':
			case '/materialdelivery/create':
			case '/quotes':
			case '/quotes/:token':
			case '/samplerequests':
			case '/samplerequests/:token':
			case '/partnumbers':
			case '/correctiveactions':
			case '/correctiveactions/:token':
			case '/warrantyReportsPtm':
			case '/warrantyReportsPtm/:token':
			case '/suppliers/ratings/:token':
			case '/indirectqualifications':
			case '/indirectqualifications/:token':
			case '/indirectqualifications/actionsPlant/:token/:supplierId':
			case '/priceChangeRequest':
			case '/priceChangeRequest/create':
			case '/priceChangeRequest/:token':
			case '/sparePartPriceChange':
			case '/sparePartPriceChange/show/:token':
				return true
			default:
				return false
		}
	}

	/**
	 * Determine if the menu option is selected
	 */
	isMenuGuaranteeSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/guaranteereport':
			case '/guaranteereport/:token':
			case '/guaranteereport/notecredit/:token':
				selectedOption = 'guaranteereport'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}
	isDistributionTransport() {
		switch (this.props.match.path) {
			case '/transportTenderShow':
			case '/transportTenderShow/:token':
				return true
			default:
				return false
		}
	}
	/**
	 * option to get avatar user
	 * @param {*} user
	 */
	getAvater(user) {
		const { classes } = this.props
		if (user.avatar_url) {
			let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${user.avatar}`
			return <Avatar src={urlImage} className={classes.avatar} data-cy='user-avatar' />
		} else {
			return (
				<Avatar data-cy='user-avatar' className={classes.avatar}>
					<PersonIcon />
				</Avatar>
			)
		}
	}

	/**
	 * Render component
	 *
	 * @returns
	 * @memberof LateralMenuExternalUser
	 */
	render() {
		const { classes, open, user, selectedCompany, advanceOptionsSupplier, totalNotifications, workshopStatus } =
			this.props
		const companyCodeExternal = this.props.user.profile.company_code
		let isActiveModuleFersa =
			this.props.advanceOptionsSupplier && this.props.advanceOptionsSupplier.is_active_module_planning_fersa
		let partnership = this.state.partnership
		return (
			<Drawer
				variant='permanent'
				className={classnames(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: classnames({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
				open={open}
			>
				<div
					className={classes.toolbar}
					style={{ padding: '0px', cursor: 'pointer' }}
					onClick={this.props.onClose}
				>
					<HeaderLogosCompany
						companyIdentifier={selectedCompany && selectedCompany.get_company_identifier}
						onClose={this.props.onClose}
					/>
				</div>

				<Divider />
				<List>
					<ListItem
						divider
						alignItems='center'
						button
						onClick={() => {
							this.props.history.push(`/externalusers/${user.profile.sub}`)
						}}
					>
						<ListItemAvatar>{this.getAvater(user)}</ListItemAvatar>
						<ListItemText secondary={<React.Fragment>{user.profile.name}</React.Fragment>} />
					</ListItem>

					<ListItem
						divider
						button
						selected={this.isMenuInitialSelected('/')}
						onClick={() => {
							this.props.history.push('/')
						}}
					>
						<ListItemIcon>
							<BusinessIcon />
						</ListItemIcon>
						<ListItemText primary={<Translate id='menu.initial' />} />
					</ListItem>

					{(roles.IsUserInRole(roles.ROLE_SUPPLIERADMINISTRATOR, this.props.userRolesByCompany) ||
						IsUserSupplierAdministrator(this.props.user) ||
						canBeModuleExpedientSupplier(this.props.userRolesByCompany)) && (
						<AdministrationItems {...this.props} isActiveAdminsitration={this.isActiveAdminsitration()} />
					)}
					{/**Certifications */}
					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
						<ListItem
							divider
							selected={this.isMenuInitialSelected('certifications')}
							button
							onClick={() => {
								this.props.history.push('/certifications')
							}}
						>
							<ListItemIcon>
								<DoneAll />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='menu.certifications' />} />
						</ListItem>
					)}
					{/**Directorio telefonico */}
					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
						// roles.CaBeShowListSuppliers(this.props.userRolesByCompany)
						<ListItem
							divider
							selected={this.isMenuInitialSelected('phoneDirectory')}
							button
							onClick={() => {
								this.props.history.push('/phoneDirectory')
							}}
						>
							<ListItemIcon>
								<PhoneDirectorycon />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='phoneDirectories.title' />} />
						</ListItem>
					)}

					{/**Directorio telefonico */}
					{selectedCompany &&
						(selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER__METALLIC) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('factoringaffiliation')}
								button
								onClick={() => {
									this.props.history.push('/factoringaffiliation')
								}}
							>
								<ListItemIcon>
									<FactoringAfiliantionIcon />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='factoringAffiliation.title' />} />
							</ListItem>
						)}
					{/**Certification */}

					{selectedCompany &&
						(selectedCompany.get_company_identifier === COMPANY_PTM ||
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER__METALLIC ||
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE ||
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_FERSA ||
							selectedCompany.company_code === COMPANY_MAQUINAS_EOS) &&
						advanceOptionsSupplier &&
						advanceOptionsSupplier.is_contractor_supplier && (
							<ContractorsItems {...this.props} isActiveContractors={this.isActiveContractors()} />
						)}

					{selectedCompany &&
						selectedCompany.company_modules &&
						selectedCompany.company_modules.OfficialNotice && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('officialnotices')}
								button
								onClick={() => {
									this.props.history.push('/officialnotices')
								}}
							>
								<ListItemIcon>
									<NoticesIcon />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.officeNotice' />} />
								{selectedCompany &&
									selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
									totalNotifications > 0 && (
										<Badge
											color='primary'
											className={classes.margin}
											badgeContent={totalNotifications}
											max={99}
											children={''}
										></Badge>
									)}
							</ListItem>
						)}
					{selectedCompany &&
						selectedCompany.company_modules &&
						selectedCompany.company_modules.PlanningModule &&
						selectedCompany.get_company_identifier === COMPANY_PTM &&
						(roles.canBeModulePlaneationSupplier(this.props.userRolesByCompany) ||
							IsUserSupplierAdministrator(this.props.user)) && (
							<PlanningItems
								{...this.props}
								companyCodeExternal={companyCodeExternal}
								isActivePlanning={this.isActivePlanning()}
							/>
						)}

					{selectedCompany &&
						selectedCompany.company_modules &&
						selectedCompany.company_modules.PlanningModule &&
						selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_FERSA &&
						(roles.canBeModulePlaneationSupplier(this.props.userRolesByCompany) ||
							IsUserSupplierAdministrator(this.props.user)) &&
						isActiveModuleFersa && (
							<PlanningItemsFersa
								{...this.props}
								companyCodeExternal={companyCodeExternal}
								isActivePlanningFersa={this.isActivePlanningFersa()}
							/>
						)}

					<ConsultasItems {...this.props} isActiveConsult={this.isActiveConsult()} />

					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE && (
						<ListItem
							divider
							selected={this.isMenuGuaranteeSelected('guaranteereport')}
							button
							onClick={() => {
								this.props.history.push('/guaranteereport')
							}}
						>
							<ListItemIcon>
								<GuaranteeReportIcon />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='menu.guaranteeReport' />} />
						</ListItem>
					)}

					<QualityAssuranceItems {...this.props} isActiveQuality={this.isActiveQuality()} />

					<SupplierDevelopmentItems {...this.props} isActiveDevelopment={this.isActiveDevelopment()} />

					{selectedCompany && selectedCompany.get_company_identifier !== COMPANY_PTM && (
						<ListItem
							divider
							selected={this.isMenuInitialSelected('warehouseRawMaterials')}
							button
							onClick={() => {
								this.props.history.push('/warehouseRawMaterials')
							}}
						>
							<ListItemIcon>
								<WarehouseRawMaterialIcon />
							</ListItemIcon>
							<ListItemText primary={<Translate id='menu.warehouseMaterial' />} />
						</ListItem>
					)}

					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
						(roles.canBeModuleLoadAlert(this.props.userRolesByCompany) ||
							roles.canSeeModuleForeignTrade(this.props.userRolesByCompany)) && (
							<ImportItems {...this.props} isActiveImports={this.isActiveImports()} />
						)}

					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
						<ExportItems {...this.props} isActiveExports={this.isActiveExports()} />
					)}

					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
						<ListItem
							divider
							selected={this.isMenuInitialSelected('deliveryQualifications')}
							button
							onClick={() => {
								this.props.history.push('/deliveryQualifications')
							}}
						>
							<ListItemIcon>
								<WarehouseRawMaterialIcon />
							</ListItemIcon>
							<ListItemText primary={<Translate id='menu.deliveryQualifications' />} />
						</ListItem>
					)}

					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_PTM &&
						(roles.canBeModulePlaneationSupplier(this.props.userRolesByCompany) ||
							IsUserSupplierAdministrator(this.props.user)) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('maquilaOrders')}
								button
								onClick={() => {
									this.props.history.push('/maquilaOrders')
								}}
							>
								<ListItemIcon>
									<MaquilaIcon />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='maquilaOrders.title' />} />
							</ListItem>
						)}
					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_PTM &&
						advanceOptionsSupplier &&
						advanceOptionsSupplier.access_patio_storage_windows && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('warehouseWindows')}
								button
								onClick={() => {
									this.props.history.push('/warehouseWindows')
								}}
							>
								<ListItemIcon>
									<WarehouseWindowsIcon />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='warehouseWindows.title' />} />
							</ListItem>
						)}

					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_PTM &&
						process.env.PUBLIC_URL !== ENVIROMENT_TYPE_PRODUCTION &&
						(roles.canBeModulePlaneationSupplier(this.props.userRolesByCompany) ||
							IsUserSupplierAdministrator(this.props.user)) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('billings')}
								button
								onClick={() => {
									this.props.history.push('/billings')
								}}
							>
								<ListItemIcon>
									<MaquilaIcon />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.billing' />} />
							</ListItem>
						)}

					{selectedCompany &&
						selectedCompany.company_modules &&
						selectedCompany.company_modules.ImportRequest &&
						(roles.canBeModuleShippingOrderSupplier(this.props.userRolesByCompany) ||
							IsUserSupplierAdministrator(this.props.user)) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('shippingorders')}
								button
								onClick={() => {
									this.props.history.push('/shippingorders')
								}}
							>
								<ListItemIcon>
									<ShippingIcon />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.shippingOrder' />} />
							</ListItem>
						)}

					{selectedCompany &&
						selectedCompany.company_modules &&
						selectedCompany.company_modules.ImportRequest &&
						(roles.canBeModuleFreightSupplier(this.props.userRolesByCompany) ||
							IsUserSupplierAdministrator(this.props.user)) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('freight')}
								button
								onClick={() => {
									this.props.history.push('/freight')
								}}
							>
								<ListItemIcon>
									<FreightIcon />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.freight' />} />
							</ListItem>
						)}
					{selectedCompany &&
						selectedCompany.company_modules &&
						selectedCompany.company_modules.Sustainability &&
						(IsUserSupplierAdministrator(this.props.user) ||
							roles.canBeModuleSustainabilitySupplier(this.props.userRolesByCompany)) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('sustainabilityv2')}
								button
								onClick={() => {
									this.props.history.push('/sustainabilityv2')
								}}
							>
								<ListItemIcon>
									<SustainabilityIcon />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.sustainability' />} />
							</ListItem>
						)}

					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_PTM && (
						<ListItem
							divider
							button
							selected={this.isMenuInitialSelected('questionnaire')}
							onClick={() => {
								this.props.history.push('/questionnaire')
							}}
						>
							<ListItemIcon>
								<QuestionnaireIcon />
							</ListItemIcon>
							<ListItemText primary={<Translate id='menu.questionnaire' />} />
						</ListItem>
					)}

					{selectedCompany &&
						workshopStatus &&
						selectedCompany.company_modules.AccreditedWorkshopsPayments && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('accreditedWorkshops')}
								button
								onClick={() => {
									this.props.history.push('/accreditedWorkshops')
								}}
							>
								<ListItemIcon>
									<AccreditedWorkShopsIcon />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.accreditedWorkshops' />} />
							</ListItem>
						)}

					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
						(roles.canBeModuleBiddingSupplier(this.props.userRolesByCompany) ||
							IsUserSupplierAdministrator(this.props.user)) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('biddings')}
								button
								onClick={() => {
									this.props.history.push('/biddings')
								}}
							>
								<ListItemIcon>
									<BiddingIcon />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.tenders' />} />
							</ListItem>
						)}

					{selectedCompany &&
						selectedCompany.company_modules.ConsignmentNote &&
						(roles.canBeModuleConsignmentNoteSupplier(this.props.userRolesByCompany) ||
							IsUserSupplierAdministrator(this.props.user)) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('consignmentNote')}
								button
								onClick={() => {
									this.props.history.push('/consignmentNote')
								}}
							>
								<ListItemIcon>
									<FindInPage />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.consignmentNote' />} />
							</ListItem>
						)}

					<ListItem
						divider
						selected={this.isMenuInitialSelected('manualsportal')}
						button
						onClick={() => {
							this.props.history.push('/manualsportal')
						}}
					>
						<ListItemIcon>
							<TutorialIcon />
						</ListItemIcon>
						<ListItemText primary={<Translate id='menu.tutorials' />} />
					</ListItem>
					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
						partnership.is_carrier && (
							<DistributionTransport
								{...this.props}
								isDistributionTransport={this.isDistributionTransport()}
							/>
						)}
				</List>
			</Drawer>
		)
	}
}

class DistributionTransport extends Component {
	/**
	 *Creates an instance of ConsultasItems.
	 * @param {*} props
	 * @memberof ConsultasItems
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			open: this.props.isDistributionTransport,
		}
	}
	isMenuDistributionTransportSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/transportTenderShow':
			case '/transportTenderShow/:token':
				selectedOption = 'transportTenderShow'
				break

			default:
				break
		}
		return menuOption === selectedOption
	}
	render() {
		const { selectedCompany } = this.props
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<ProspectSupplierIncon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.distributionTransport' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
							<ListItem
								divider
								selected={this.isMenuDistributionTransportSelected('transportTenderShow')}
								button
								onClick={() => {
									this.props.history.push('/transportTenderShow')
								}}
							>
								<ListItemIcon>
									<TutorialIcon color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.transportTender' />} />
							</ListItem>
						)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

/**
 * Consultas menu items
 *
 * @class ConsultasItems
 * @extends {Component}
 */
class ConsultasItems extends Component {
	/**
	 *Creates an instance of ConsultasItems.
	 * @param {*} props
	 * @memberof ConsultasItems
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActiveConsult,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuConsultSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/suppliers/invoices/:token':
				selectedOption = 'suppliers/invoices'
				break
			case '/suppliers/purchaseOrders/:token':
				selectedOption = 'suppliers/purchaseOrders'
				break
			case '/suppliers/inventories/:token':
				selectedOption = 'suppliers/inventories'
				break
			case '/suppliers/consignments/:token':
				selectedOption = 'suppliers/consignments'
				break
			case '/suppliers/stocks/:token':
				selectedOption = 'suppliers/stocks'
				break
			case '/suppliers/inventorymaquila/:token':
				selectedOption = 'suppliers/inventorymaquila'
				break
			default:
				return false
		}
		return menuOption === selectedOption
	}

	/**
	 * Render component
	 *
	 * @returns
	 * @memberof ConsultasItems
	 */
	render() {
		let companyTokenExternal = this.props.user.profile.company_token
		let selectedCompany = this.props.selectedCompany
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<SearchIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.consults' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.Consultation &&
							(roles.canBeConsultInvocesSupplier(this.props.userRolesByCompany) ||
								IsUserSupplierAdministrator(this.props.user)) && (
								<ListItem
									divider
									selected={this.isMenuConsultSelected('suppliers/invoices')}
									button
									onClick={() => {
										this.props.history.push(`/suppliers/invoices/${companyTokenExternal}`)
									}}
								>
									<ListItemIcon>
										<InvoicesIcon color='primary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.invoice' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.Inventories && (
								<ListItem
									divider
									selected={this.isMenuConsultSelected('suppliers/inventories')}
									button
									onClick={() => {
										this.props.history.push(`/suppliers/inventories/${companyTokenExternal}`)
									}}
								>
									<ListItemIcon>
										<InventoryIcon color='primary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.inventory' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.PurchaseOrders && (
								<ListItem
									divider
									selected={this.isMenuConsultSelected('suppliers/purchaseOrders')}
									button
									onClick={() => {
										this.props.history.push(`/suppliers/purchaseOrders/${companyTokenExternal}`)
									}}
								>
									<ListItemIcon>
										<PurchaseOrdersIcon color='primary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.purchaseOrder' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.Consignment && (
								<ListItem
									divider
									selected={this.isMenuConsultSelected('suppliers/consignments')}
									button
									onClick={() => {
										this.props.history.push(`/suppliers/consignments/${companyTokenExternal}`)
									}}
								>
									<ListItemIcon>
										<ConsignationIcon color='primary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.consignation' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.WarehouseEntries && (
								<ListItem
									divider
									selected={this.isMenuConsultSelected('suppliers/stocks')}
									button
									onClick={() => {
										this.props.history.push(`/suppliers/stocks/${companyTokenExternal}`)
									}}
								>
									<ListItemIcon>
										<WarehouseEntriesIcon color='primary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.entryStock' />} />
								</ListItem>
							)}

						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.WarehouseEntries && (
								<ListItem
									divider
									selected={this.isMenuConsultSelected('suppliers/inventorymaquila')}
									button
									onClick={() => {
										this.props.history.push(`/suppliers/inventorymaquila/${companyTokenExternal}`)
									}}
								>
									<ListItemIcon>
										<InventoryMaquilaIcon color='primary' />
									</ListItemIcon>
									<ListItemText
										inset
										primary={<Translate id='queries.inventoryMaquila.inventoryMaquila' />}
									/>
								</ListItem>
							)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

/**
 * Desarrollo de proveedores menu item
 *
 * @class DesarrolloProveedoresItems
 * @extends {Component}
 */
class SupplierDevelopmentItems extends Component {
	/**
	 *Creates an instance of ConsultasItems.
	 * @param {*} props
	 * @memberof ConsultasItems
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActiveDevelopment,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuDevelopmentSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/memos':
			case '/memos/:token':
				selectedOption = 'memos'
				break
			case '/noticechanges':
			case '/noticechanges/:token':
			case '/noticechanges/notification/:token':
				selectedOption = 'noticechanges'
				break
			case '/materialdelivery':
			case '/materialdelivery/:token':
				selectedOption = 'materialdelivery'
				break
			case '/alternativeprovedure':
			case '/alternativeprovedure/:token':
				selectedOption = 'alternativeprovedure'
				break
			case '/quotes':
			case '/quotes/:token':
				selectedOption = 'quotes'
				break
			case '/samplerequests':
			case '/samplerequests/:token':
				selectedOption = 'samplerequests'
				break
			case '/partnumbers':
				selectedOption = 'partnumbers'
				break
			case '/correctiveactions':
			case '/correctiveactions/:token':
				selectedOption = 'correctiveactions'
				break
			case '/suppliers/ratings/:token':
				selectedOption = 'suppliers/ratings'
				break
			case '/indirectqualifications':
			case '/indirectqualifications/:token':
			case '/indirectqualifications/actionsPlant/:token/:supplierId':
				selectedOption = 'indirectqualifications'
				break
			case '/priceChangeRequest':
			case '/priceChangeRequest/:token':
			case '/priceChangeRequest/create':
				selectedOption = 'priceChangeRequest'
				break
			case '/sparePartPriceChange':
			case '/sparePartPriceChange/show/:token':
				selectedOption = 'sparePartPriceChange'
				break
			case '/indirectPriceQuoteWithoutCode':
			case '/indirectPriceQuoteWithoutCode/:token':
			case '/indirectPriceQuoteWithoutCode/:token/show':
				selectedOption = 'indirectPriceQuoteWithoutCode'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		let companyTokenExternal = this.props.user.profile.company_token
		const { selectedCompany } = this.props
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<SupplierDevelopmentIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.developmentSuppliers' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.get_company_identifier !== COMPANY_PTM &&
							selectedCompany.company_modules.Memos && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('memos')}
									button
									onClick={() => {
										this.props.history.push('/memos')
									}}
								>
									<ListItemIcon>
										<MemosIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.memos' />} />
								</ListItem>
							)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_PTM && (
							<ListItem
								divider
								selected={this.isMenuDevelopmentSelected('materialdelivery')}
								button
								onClick={() => {
									this.props.history.push('/materialdelivery')
								}}
							>
								<ListItemIcon>
									<MemosIcon color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.materialDelivery' />} />
							</ListItem>
						)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.NoticeChanges && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('noticechanges')}
									button
									onClick={() => {
										this.props.history.push('/noticechanges')
									}}
								>
									<ListItemIcon>
										<NoticesOfChangeIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.noticeChange' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.AlternativeProvedure && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('alternativeprovedure')}
									button
									onClick={() => {
										this.props.history.push('/alternativeprovedure')
									}}
								>
									<ListItemIcon>
										<AlternativeSupplierIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.alternatingProveeduria' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
							selectedCompany.company_modules && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('priceChangeRequest')}
									button
									onClick={() => {
										this.props.history.push('/priceChangeRequest')
									}}
								>
									<ListItemIcon>
										<CreditCard color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.priceChangeRequest' />} />
								</ListItem>
							)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_PTM && (
							<ListItem
								divider
								selected={this.isMenuDevelopmentSelected('sparePartPriceChange')}
								button
								onClick={() => {
									this.props.history.push('/sparePartPriceChange')
								}}
							>
								<ListItemIcon>
									<CreditCard color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.priceChangeRequestPTM' />} />
							</ListItem>
						)}
						{selectedCompany &&
							(selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
								selectedCompany.get_company_identifier === COMPANY_IDENTIFIER__METALLIC) &&
							selectedCompany.company_modules && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('indirectPriceQuoteWithoutCode')}
									button
									onClick={() => {
										this.props.history.push('/indirectPriceQuoteWithoutCode')
									}}
								>
									<ListItemIcon>
										<CreditCard color='secondary' />
									</ListItemIcon>
									<ListItemText
										inset
										primary={<Translate id='menu.indirectPriceQuoteWithoutCode' />}
									/>
								</ListItem>
							)}

						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.Quotes && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('quotes')}
									button
									onClick={() => {
										this.props.history.push('/quotes')
									}}
								>
									<ListItemIcon>
										<QuotesIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.quotes' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.Samples && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('samplerequests')}
									button
									onClick={() => {
										this.props.history.push('/samplerequests')
									}}
								>
									<ListItemIcon>
										<SamplesIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.sample' />} />
								</ListItem>
							)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
							<ListItem
								divider
								selected={this.isMenuDevelopmentSelected('partnumbers')}
								button
								onClick={() => {
									this.props.history.push('/partnumbers')
								}}
							>
								<ListItemIcon>
									<TrazabilityIcon color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.trazability' />} />
							</ListItem>
						)}
						<ListItem
							divider
							selected={this.isMenuDevelopmentSelected('suppliers/ratings')}
							button
							onClick={() => {
								this.props.history.push(`/suppliers/ratings/${companyTokenExternal}`)
							}}
						>
							<ListItemIcon>
								<RatesIcon color='secondary' />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='menu.ratings' />} />
						</ListItem>
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.SacsDevelopment && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('correctiveactions')}
									button
									onClick={() => {
										this.props.history.push('/correctiveactions')
									}}
								>
									<ListItemIcon>
										<SACRequestIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.correctiveActionDevelopment' />} />
								</ListItem>
							)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
							<ListItem
								divider
								selected={this.isMenuDevelopmentSelected('indirectqualifications')}
								button
								onClick={() => {
									this.props.history.push('/indirectqualifications')
								}}
							>
								<ListItemIcon>
									<IndirectQualificationIcon color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.indirectQualifications' />} />
							</ListItem>
						)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

/**
 * Aseguramiento de calidad menu item
 *
 * @class QualityAssuranceItem
 * @extends {Component}
 */
class QualityAssuranceItems extends Component {
	/**
	 *Creates an instance of ConsultasItems.
	 * @param {*} props
	 * @memberof QualityAssuranceItem
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActiveQuality,
			openSpecification: this.props.isActiveQuality,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuQualitySelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/rawmaterialnonconformity':
			case '/rawmaterialnonconformity/:token':
				selectedOption = 'rawmaterialnonconformity'
				break
			case '/qualityfailurereport':
			case '/qualityfailurereport/:token':
				selectedOption = 'qualityfailurereport'
				break
			case '/reworks':
			case '/reworks/:token':
			case '/reworks/reworkreport/:token':
				selectedOption = 'reworks'
				break
			case '/sacsquality':
			case '/sacsquality/:token':
			case '/sacsquality/createActionsBySupplier/:token':
				selectedOption = 'sacsquality'
				break
			case '/devolutions':
			case '/devolutions/:token':
				selectedOption = 'devolutions'
				break
			case '/warrantyReportsPtm':
			case '/warrantyReportsPtm/:token':
				selectedOption = 'warrantyReportsPtm'
				break
			case '/qualityaudit':
			case '/qualityaudit/:token':
				selectedOption = 'qualityaudit'
				break
			case '/qualityCertificate':
			case '/qualityCertificate/:token':
				selectedOption = 'qualityCertificate'
				break
			case '/specifications/guidelines':
			case '/specifications/guidelines/:token':
				selectedOption = 'guidelines'
				break
			case '/specifications/package':
			case '/specifications/package/:token':
				selectedOption = 'package'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		const { selectedCompany } = this.props
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<QualityAssuranceIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.quality' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.NonConformityMaterial && (
								<ListItem
									divider
									selected={this.isMenuQualitySelected('rawmaterialnonconformity')}
									button
									onClick={() => {
										this.props.history.push('/rawmaterialnonconformity')
									}}
								>
									<ListItemIcon>
										<RawMaterialNonConfIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.rawMaterialsNotification' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.QualityFailureReports && (
								<ListItem
									divider
									selected={this.isMenuQualitySelected('qualityfailurereport')}
									button
									onClick={() => {
										this.props.history.push('/qualityfailurereport')
									}}
								>
									<ListItemIcon>
										<QualityFailureReportIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.failureReportQuality' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.Rework && (
								<ListItem
									divider
									selected={this.isMenuQualitySelected('reworks')}
									button
									onClick={() => {
										this.props.history.push('/reworks')
									}}
								>
									<ListItemIcon>
										<ReworkIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.rework' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.SacsQuality && (
								<ListItem
									divider
									selected={this.isMenuQualitySelected('sacsquality')}
									button
									onClick={() => {
										this.props.history.push('/sacsquality')
									}}
								>
									<ListItemIcon>
										<SacsQualityIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.correctiveActionQuality' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.Devolutions && (
								<ListItem
									divider
									selected={this.isMenuQualitySelected('devolutions')}
									button
									onClick={() => {
										this.props.history.push('/devolutions')
									}}
								>
									<ListItemIcon>
										<DevolutionIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.devolutions' />} />
								</ListItem>
							)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
							<ListItem
								divider
								selected={this.isMenuQualitySelected('qualityaudit')}
								button
								onClick={() => {
									this.props.history.push('/qualityaudit')
								}}
							>
								<ListItemIcon>
									<FindInPage color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.audit' />} />
							</ListItem>
						)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_PTM && (
							<ListItem
								divider
								selected={this.isMenuQualitySelected('warrantyReportsPtm')}
								button
								onClick={() => {
									this.props.history.push('/warrantyReportsPtm')
								}}
							>
								<ListItemIcon>
									<WarrantyQualityIcon color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={'Reporte de garantías'} />
							</ListItem>
						)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
							<ListItem
								divider
								selected={this.isMenuQualitySelected('qualityCertificate')}
								button
								onClick={() => {
									this.props.history.push('/qualityCertificate')
								}}
							>
								<ListItemIcon>
									<CreditCardOutlined color='secondary' />
								</ListItemIcon>
								<ListItemText
									inset
									primary={<Translate id='qualityCertificate.qualityCertificate' />}
								/>
							</ListItem>
						)}
						{process.env.PUBLIC_URL !== ENVIROMENT_TYPE_PRODUCTION &&
							selectedCompany &&
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
								<React.Fragment>
									<ListItem
										divider
										button
										onClick={() => {
											this.setState({
												open: true,
												openSpecification: !this.state.openSpecification,
											})
										}}
									>
										<ListItemIcon>
											<Assignment color='secondary' />
										</ListItemIcon>
										<ListItemText inset primary={<Translate id='menu.specifications' />} />
										{!this.state.openSpecification ? <ExpandLess /> : <ExpandMore />}
									</ListItem>
									<Collapse in={this.state.openSpecification} timeout='auto' unmountOnExit>
										{selectedCompany &&
											selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
												<List>
													<ListItem
														divider
														selected={this.isMenuQualitySelected('guidelines')}
														button
														onClick={() => {
															this.props.history.push('/specifications/guidelines')
														}}
													>
														<ListItemIcon style={{ marginLeft: '15px' }}>
															<Poll color='secondary' />
														</ListItemIcon>
														<ListItemText
															inset
															primary={
																<Translate id='specificationsSection.guidelines.tileMenu' />
															}
														/>
													</ListItem>
													<ListItem
														divider
														selected={this.isMenuQualitySelected('package')}
														button
														onClick={() =>
															this.props.history.push('/specifications/package')
														}
													>
														<ListItemIcon style={{ marginLeft: '15px' }}>
															<Description color='secondary' />
														</ListItemIcon>
														<ListItemText
															inset
															primary={
																<Translate id='specificationsSection.package.title' />
															}
														/>
													</ListItem>
												</List>
											)}
									</Collapse>
								</React.Fragment>
							)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

/**
 * Modulo de administración
 */
class AdministrationItems extends Component {
	/**
	 *Creates an instance of AdministrationItems.
	 * @param {*} props
	 * @memberof AdministrationItems
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActiveAdminsitration,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuAdministrationSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/externalusers':
			case '/externalusers/:token':
			case '/externalusers/profile/:token':
				selectedOption = 'externalusers'
				break
			case '/suppliers/supplier/profile':
			case '/suppliers/ratings/:token':
				selectedOption = 'suppliers/supplier/profile'
				break
			case '/expedientsbysupplier':
			case '/expedientsbysupplier/:token':
				selectedOption = 'expedientsbysupplier'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		const { selectedCompany } = this.props
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<AdministrationIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.administration' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{IsUserSupplierAdministrator(this.props.user) && (
							<ListItem
								divider
								button
								selected={this.isMenuAdministrationSelected('suppliers/supplier/profile')}
								onClick={() => {
									this.props.history.push('/suppliers/supplier/profile')
								}}
							>
								<ListItemIcon>
									<MemosIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.myInformation' />} />
							</ListItem>
						)}
						{(IsUserSupplierAdministrator(this.props.user) ||
							roles.canBeAdminsitratorSupplier(this.props.userRolesByCompany)) && (
							<ListItem
								divider
								button
								selected={this.isMenuAdministrationSelected('externalusers')}
								onClick={() => {
									this.props.history.push('/externalusers')
								}}
							>
								<ListItemIcon>
									<UsersManagementIcon color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.users' />} />
							</ListItem>
						)}
						{selectedCompany &&
							(selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
								selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE ||
								selectedCompany.get_company_identifier === COMPANY_IDENTIFIER__METALLIC ||
								selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_FERSA ||
								selectedCompany.get_company_identifier === COMPANY_PTM) && (
								<ListItem
									divider
									selected={this.isMenuAdministrationSelected('expedientsbysupplier')}
									button
									onClick={() => {
										this.props.history.push('/expedientsbysupplier')
									}}
								>
									<ListItemIcon>
										<ExpedientsIcon color='secondary' />
									</ListItemIcon>
									<ListItemText
										inset
										primary={<Translate id='expedientSupplier.expedientsTitle' />}
									/>
								</ListItem>
							)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

/**
 * Contractors Module
 *
 * @class ContractorsItems
 * @extends {Component}
 */
class ContractorsItems extends Component {
	/**
	 *Creates an instance of ContractorsItems.
	 * @param {*} props
	 * @memberof ContractorsItems
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActiveContractors,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuContractorsSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/employees':
			case '/employees/cards':
			case '/employees/:token':
				selectedOption = 'employees'
				break
			case '/socialinsurancepayments/':
			case '/socialinsurancepayments/:token':
				selectedOption = 'socialinsurancepayments'
				break
			case '/servicesprojects/':
			case '/servicesprojects/:token':
				selectedOption = 'servicesprojects'
				break
			case '/medicalchecks/':
			case '/medicalchecks/:token':
				selectedOption = 'medicalchecks'
				break
			case '/trainings/':
			case '/trainings/:token':
				selectedOption = 'trainings'
				break
			case '/securityCourses':
			case '/securityCourses/:token':
				selectedOption = 'securityCourses'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		const { selectedCompany } = this.props
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<ContractorsIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.contractors' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						<ListItem
							divider
							button
							selected={this.isMenuContractorsSelected('employees')}
							onClick={() => {
								this.props.history.push('/employees')
							}}
						>
							<ListItemIcon>
								<EmployeesIcon color='secondary' />
							</ListItemIcon>
							<ListItemText primary={<Translate id='menu.employees' />} />
						</ListItem>
						<ListItem
							divider
							button
							selected={this.isMenuContractorsSelected('socialinsurancepayments')}
							onClick={() => {
								this.props.history.push('/socialinsurancepayments')
							}}
						>
							<ListItemIcon>
								<SocialSecurityIcon color='secondary' />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='menu.socialSecurity' />} />
						</ListItem>
						<ListItem
							divider
							button
							selected={this.isMenuContractorsSelected('servicesprojects')}
							onClick={() => {
								this.props.history.push('/servicesprojects')
							}}
						>
							<ListItemIcon>
								<ServicesProjectsIcon color='secondary' />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='menu.servicesProjects' />} />
						</ListItem>
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_PTM && (
							<ListItem
								divider
								selected={this.isMenuContractorsSelected('medicalchecks')}
								button
								onClick={() => {
									this.props.history.push('/medicalchecks')
								}}
							>
								<ListItemIcon>
									<MedicalChecksIcon color='secondary' />
								</ListItemIcon>
								<ListItemText
									inset
									primary={<Translate id='contractorsModule.medicalchecks.medicalchecksTitle' />}
								/>
							</ListItem>
						)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_PTM && (
							<ListItem
								divider
								selected={this.isMenuContractorsSelected('trainings')}
								button
								onClick={() => {
									this.props.history.push('/trainings')
								}}
							>
								<ListItemIcon>
									<School color='secondary' />
								</ListItemIcon>
								<ListItemText
									inset
									primary={<Translate id='contractorsModule.medicalchecks.trainingsTitle' />}
								/>
							</ListItem>
						)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.OfficialNotice &&
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
							process.env.PUBLIC_URL !== ENVIROMENT_TYPE_PRODUCTION && (
								<ListItem
									divider
									selected={this.isMenuContractorsSelected('securityCourses')}
									button
									onClick={() => {
										this.props.history.push('/securityCourses')
									}}
								>
									<ListItemIcon>
										<Security color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.securityCourses' />} />
								</ListItem>
							)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

class PlanningItems extends Component {
	/**
	 *Creates an instance of ContractorsItems.
	 * @param {*} props
	 * @memberof ContractorsItems
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActivePlanning,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuPlanningSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/planning/releaseplan':
			case '/planning/releaseplan/:token':
				selectedOption = 'planning/releaseplan'
				break
			case '/planning/deliveryorder':
			case '/planning/deliveryorder/create':
			case '/planning/deliveryorder/:token':
				selectedOption = 'planning/deliveryorder'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<PurchaseIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.deliveryPlan' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						<ListItem
							divider
							selected={this.isMenuPlanningSelected('planning/releaseplan')}
							button
							onClick={() => {
								this.props.history.push(`/planning/releaseplan/${this.props.companyCodeExternal}`)
							}}
						>
							<ListItemIcon>
								<PurchaseIcon color='secondary' />
							</ListItemIcon>
							<ListItemText primary={<Translate id='menu.deliveryPlan' />} />
						</ListItem>
						<ListItem
							divider
							button
							selected={this.isMenuPlanningSelected('planning/deliveryorder')}
							onClick={() => {
								this.props.history.push('/planning/deliveryorder')
							}}
						>
							<ListItemIcon>
								<DeliveryOrdersIcon color='secondary' />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='menu.deliveryOrder' />} />
						</ListItem>
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

class PlanningItemsFersa extends Component {
	/**
	 *Creates an instance of ContractorsItems.
	 * @param {*} props
	 * @memberof ContractorsItems
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActivePlanningFersa,
		}
	}

	isMenuPlanningSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/planning/relist':
			case '/planning/relist/:supplierCode':
				selectedOption = 'planning/relist'
				break
			case '/planning':
			case '/planning/deliveryOrderFersa/create':
			case '/planning/deliveryOrderFersa/:token':
			case '/deliveryOrderFersa/registerarrival':
				selectedOption = 'planning'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<PurchaseIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.deliveryPlan' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						<ListItem
							divider
							selected={this.isMenuPlanningSelected('planning/relist')}
							button
							onClick={() => {
								this.props.history.push(`/planning/relist/${this.props.companyCodeExternal}`)
							}}
						>
							<ListItemIcon>
								<PurchaseIcon color='primary' />
							</ListItemIcon>
							<ListItemText primary={<Translate id='menu.deliveryPlan' />} />
						</ListItem>
						<ListItem
							divider
							button
							selected={this.isMenuPlanningSelected('planning')}
							onClick={() => {
								this.props.history.push(`/planning`)
							}}
						>
							<ListItemIcon>
								<DeliveryOrdersIcon color='primary' />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='menu.deliveryOrder' />} />
						</ListItem>
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

class ImportItems extends Component {
	/**
	 *Creates an instance of ImportItems.
	 * @param {*} props
	 * @memberof ImportItems
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActiveImports,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuImportsSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/importRequests':
			case '/importRequests/:token':
				selectedOption = 'importRequests'
				break
			case '/loadAlerts':
			case '/loadAlerts/:token':
				selectedOption = 'loadAlerts'
				break
			case '/loadalerttracking':
				selectedOption = 'loadalerttracking'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<AdministrationIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.ControlTower' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{roles.canSeeModuleForeignTrade(this.props.userRolesByCompany) && (
							<ListItem
								divider
								button
								selected={this.isMenuImportsSelected('importRequests')}
								onClick={() => {
									this.props.history.push('/importRequests')
								}}
							>
								<ListItemIcon>
									<MemosIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.importRequests' />} />
							</ListItem>
						)}

						{roles.canSeeModuleForeignTrade(this.props.userRolesByCompany) && (
							<ListItem
								divider
								button
								selected={this.isMenuImportsSelected('searchPartNumbers')}
								onClick={() => {
									this.props.history.push('/searchPartNumbers')
								}}
							>
								<ListItemIcon>
									<FormatListNumberedIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.partNumber' />} />
							</ListItem>
						)}
						{roles.canBeModuleLoadAlert(this.props.userRolesByCompany) && (
							<ListItem
								divider
								button
								selected={this.isMenuImportsSelected('loadAlerts')}
								onClick={() => {
									this.props.history.push('/loadAlerts')
								}}
							>
								<ListItemIcon>
									<Notifications color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.loadAlerts' />} />
							</ListItem>
						)}

						{false && roles.canBeModuleLoadAlert(this.props.userRolesByCompany) && (
							<ListItem
								divider
								button
								selected={this.isMenuImportsSelected('loadalerttracking')}
								onClick={() => {
									this.props.history.push('/loadalerttracking')
								}}
							>
								<ListItemIcon>
									<ViewList color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.loadAlertTracking' />} />
							</ListItem>
						)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

class ExportItems extends Component {
	/**
	 *Creates an instance of ExportItems.
	 * @param {*} props
	 * @memberof ExportItems
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActiveExports,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuExportsSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/exportRequests':
			case '/exportRequests/:token':
				selectedOption = 'exportRequests'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<AdministrationIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.ControlTowerExport' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						<ListItem
							divider
							button
							selected={this.isMenuExportsSelected('exportRequests')}
							onClick={() => {
								this.props.history.push('/exportRequests')
							}}
						>
							<ListItemIcon>
								<MemosIcon color='secondary' />
							</ListItemIcon>
							<ListItemText primary={<Translate id='menu.exportRequests' />} />
						</ListItem>
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	const user = state.oidc.user ? state.oidc.user : { profile: { sub: '', name: '', email: '', user_type: '' } }
	const selectedCompany = state.profile.get('selectedCompany')
	const workshopStatus = state.accreditedWorkshops.get('workshopStatus')
	const partnership = state.suppliers.get('partnership') ? state.suppliers.get('partnership').toJS() : null

	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		workshopStatus,
		partnership: partnership,
	}
}

/**
 * Mapping dispatch to props
 * @param {*} dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
	return {
		fetchAccreditedWorkshopStatus: (companyCode) => {
			return dispatch(loadAccreditedWorkshopStatus(companyCode))
		},
		loadPartnership: (supplierCode, companyToken) => {
			return dispatch(loadPartnership(supplierCode, companyToken))
		},
	}
}

const drawerWidth = 240
const styles = (theme) => ({
	avatar: {
		color: theme.palette.secondary.main,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing.unit * 7 + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing.unit * 9 + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	root: {
		flexGrow: 1,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
	},
})

export default withLocalize(
	withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(LateralMenuExternalUser))
)
