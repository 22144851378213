//import react section
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
/** Material UI imports section */
import { Button, Card, CardContent, Fab, Paper, Typography, withStyles } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/MenuOutlined'
import LoadingPage from '../../../common/LoadingPage'
import LayoutHeaderWorkFlow from '../../../common/LayoutHeaderWorkFlow'
import HeaderComponent from './HeaderComponent'
import SupplierResponses from './SupplierResponses'
import { IndirectPriceQuoteSupplierStatus } from '../../../../store/helpers/AppConstants'

const DesktopView = (props) => {
	//Bool props ection
	const { isLoading, isSaving, canBeEditSupplier } = props
	//objects props ection
	const { indirectPriceQuoteSupplier, selectedCompany, supplier } = props
	//function props section
	const {
		updateIndirectPriceQuoteItemProperty,
		updatePriceQuoteWithOutCodeSupplierProperty,
		onOpenCloseDialogOperation,
		onGeneratedNewPDFQuoteWithOutPrice,
		onUploadFilesPriceQuoteWithOutCodeSupplier,
		onRemoveFilesPriceQuoteWithOutCodeSupplier,
		onIndirectPriceQuotePdfRegeneration,
	} = props
	if (isLoading || supplier === null || indirectPriceQuoteSupplier === null) return <LoadingPage />

	let statusConfiguration = {
		label: '',
		color: '',
	}
	switch (indirectPriceQuoteSupplier.Status) {
		case IndirectPriceQuoteSupplierStatus.SEND_TO_SUPPLIER:
			statusConfiguration.label = 'Enviado a proveedor'
			statusConfiguration.color = 'primary' // Azul
			break
		case IndirectPriceQuoteSupplierStatus.QUOTED:
			statusConfiguration.label = 'Cotizado'
			statusConfiguration.color = 'success'
			break
		case IndirectPriceQuoteSupplierStatus.CANCELLED:
			statusConfiguration.label = 'Cancelado'
			statusConfiguration.color = 'danger'
			break
		case IndirectPriceQuoteSupplierStatus.NO_ANSWER:
			statusConfiguration.label = 'No respondio'
			statusConfiguration.color = 'warning'
			break
		default:
			statusConfiguration.label = 'Desconocido'
			statusConfiguration.color = 'warning'
			break
	}

	return (
		<Paper style={{ padding: '10px' }}>
			{canBeEditSupplier && (
				<Fab
					variant='extended'
					size='small'
					color='primary'
					// className={classes.margin}
					// style={{

					// }}
					onClick={onOpenCloseDialogOperation}
				>
					{/* <MenuIcon className={classes.extendedIcon} /> */}
					<MenuIcon />
					{<Translate id='common.AvailableOperations' />}
				</Fab>
			)}

			{indirectPriceQuoteSupplier && indirectPriceQuoteSupplier.IndirectPriceQuoteWithoutCode && (
				<LayoutHeaderWorkFlow
					workFlow={{
						creation_date_long: indirectPriceQuoteSupplier
							? indirectPriceQuoteSupplier.IndirectPriceQuoteWithoutCode.CreationDateLong
							: '-',
						folio: indirectPriceQuoteSupplier
							? indirectPriceQuoteSupplier.IndirectPriceQuoteWithoutCode.FolioSIT
							: '-',
						origin: 'SIT',
						create_by: indirectPriceQuoteSupplier
							? indirectPriceQuoteSupplier.IndirectPriceQuoteWithoutCode.CreateBy
							: '-',
						full_supplier: `${indirectPriceQuoteSupplier ? indirectPriceQuoteSupplier.Code : '-'} | ${
							indirectPriceQuoteSupplier ? indirectPriceQuoteSupplier.Name : '-'
						}`,
						class_status: `badge badge-${statusConfiguration.color}`,
						actual_operation_description: statusConfiguration.label,
					}}
					supplier={supplier}
					company={selectedCompany}
					title={'Cotización de indirectos sin código'}
				/>
			)}
			{indirectPriceQuoteSupplier.Status === IndirectPriceQuoteSupplierStatus.QUOTED && (
				<Card style={{ borderRadius: '12px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
					<CardContent>
						<Typography variant='h6' style={{ marginBottom: '8px' }}>
							Acciones de cotización
						</Typography>
						<Button
							variant='contained'
							color='primary'
							onClick={onGeneratedNewPDFQuoteWithOutPrice}
							style={{ marginRight: '8px' }}
							disabled={isSaving}
						>
							Descargar Cotización
						</Button>
						<Button variant='outlined' color='primary' onClick={onIndirectPriceQuotePdfRegeneration} disabled={isSaving}>
							Regenerar PDF Cotización
						</Button>
					</CardContent>
				</Card>
			)}

			{indirectPriceQuoteSupplier && (
				<HeaderComponent
					indirectPriceQuoteSupplier={indirectPriceQuoteSupplier}
					//functions
					updatePriceQuoteWithOutCodeSupplierProperty={updatePriceQuoteWithOutCodeSupplierProperty}
					isSaving={isSaving}
					canBeEditSupplier={canBeEditSupplier}
					onUploadFilesPriceQuoteWithOutCodeSupplier={onUploadFilesPriceQuoteWithOutCodeSupplier}
					onRemoveFilesPriceQuoteWithOutCodeSupplier={onRemoveFilesPriceQuoteWithOutCodeSupplier}
				/>
			)}
			{/* <Typography color='primary'>Información general</Typography> */}
			{indirectPriceQuoteSupplier &&
				indirectPriceQuoteSupplier.Items.map((item) => {
					return (
						<SupplierResponses
							//Objects
							indirectPriceQuoteSupplier={indirectPriceQuoteSupplier}
							item={item}
							//functions
							updateIndirectPriceQuoteItemProperty={updateIndirectPriceQuoteItemProperty}
							//bool props
							isSaving={isSaving}
							canBeEditSupplier={canBeEditSupplier}
						/>
					)
				})}
		</Paper>
	)
}

/** Resources import section */
const useStyle = (theme) => ({})

export default withRouter(withStyles(useStyle, { withTheme: true })(DesktopView))
