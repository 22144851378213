import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
//Import components section
import DesktopView from './components/show/DesktopView'
import { generatedNewPDFQuoteWithOutPrice, loadIndirectPriceQuoteWithoutInternalUserDetailFromBackEnd } from '../../store/indirectPriceQuoteWithoutCode/IndirectPriceQuoteWithoutCodeAction'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
//Import store section
import { toSafeObject } from '../../store/helpers/ListHelper'
const Show = (props) => {
	const params = useParams()
	const dispatch = useDispatch()

	const isLoading = useSelector((state) =>
		state.indirectPriceQuoteWithoutCode.get('isLoading')
			? state.indirectPriceQuoteWithoutCode.get('isLoading')
			: false
	)

	const selectedCompany = useSelector((state) =>
		state.profile.get('selectedCompany') ? toSafeObject(state.profile.get('selectedCompany')) : null
	)

	const indirectPriceQuoteWithoutCode = useSelector((state) =>
		state.indirectPriceQuoteWithoutCode.get('indirectPriceQuoteWithoutCodeDetail')
			? state.indirectPriceQuoteWithoutCode.get('indirectPriceQuoteWithoutCodeDetail').toJS()
			: null
	)
	useEffect(() => {
		if(params.token)
			dispatch(loadIndirectPriceQuoteWithoutInternalUserDetailFromBackEnd(params.token)) //sortCriteria.by
	}, [dispatch, params.token])

	const onRedirectToDetail = (token) => props.history.push(`/indirectPriceQuoteWithoutCode/${token}/show`)

	const onGeneratedNewPDFQuoteWithOutPrice = (item) => {
		let token = item.token
		return dispatch(generatedNewPDFQuoteWithOutPrice(token))
			.then(() => {
				// setToasterMessageConfig({
				// 	showToaster: true,
				// 	toasterVariant: 'success',
				// 	toasterMessage: 'common.saveCorrectly',
				// })
			})
			.catch((response) => {
				// setToasterMessageConfig({
				// 	showToaster: true,
				// 	toasterMessage: 'common.errorToSave',
				// 	toasterVariant: 'error',
				// })
			})
	}

	return (
		<DesktopView
			// bool
			isLoading={isLoading}
			//object
			indirectPriceQuoteWithoutCode={indirectPriceQuoteWithoutCode}
			selectedCompany={selectedCompany}
			//functions
			onRedirectToDetail={onRedirectToDetail}
			onGeneratedNewPDFQuoteWithOutPrice={onGeneratedNewPDFQuoteWithOutPrice}
		/>
	)
}

const styles = (theme) => ({})

// export default withauthenticatedLayout(Show)
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Show)))
